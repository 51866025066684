import { Routes, Route, useLocation } from "react-router-dom";
import { RegistryFormComponent } from "./pages/form";
import { AdminRoutes } from "./pages/admin";
import { NavBar } from "./components/NavBar";
import { Footer } from "./components/Footer";
import { LoginPage } from "./pages/admin/Login";
import { Home } from "./pages/home";
import { AydinlatmaMetni, AcikRiza } from "./pages/documents";
import { NotFound } from "./components/NotFound";
import { KVKK } from "./pages/kvkk/index";
import { importAll } from "./tools/importAll";

import "./App.css";
import { BirthDayPage } from "./pages/birthday";
import { useState } from "react";

function App() {
  const [hideNavigationElements, setHideNavigationElements] = useState(false);

  const subViews = importAll(
    require.context("./pages/subViews", true, /\.parentPage.(js|jsx)$/)
  );

  let search = useLocation().search;

  return (
    <>
      {!hideNavigationElements && <NavBar />}
      <Routes>
        <Route
          path="/ogrencibasvuru"
          exact
          element={<RegistryFormComponent />}
        />
        {
          //<Route path="/" exact element={<Navigate replace to="/ogrencibasvuru" />} />}
        }
        <Route path={`/admin/login`} element={<LoginPage params={search} />} />
        <Route path="/" exact element={<Home />} />
        <Route
          path="/documents/aydinlatma-metni"
          element={<AydinlatmaMetni />}
        />
        <Route path="/documents/acik-riza" element={<AcikRiza />} />
        <Route path="/kvkk" element={<KVKK />} />
        <Route
          path="/mesaj"
          element={
            <BirthDayPage
              params={search}
              hideNavigation={setHideNavigationElements}
            />
          }
        />
        {AdminRoutes().map((e) => e)}
        {Object.keys(subViews).map((key, i) => {
          let view = subViews[key].default;
          let Element = view[1];
          return (
            <Route key={i} path={`/${view[0].pathName}`}>
              <Route path={`/${view[0].pathName}`} element={<Element />} />
              {view[2]().map((e) => e)}
            </Route>
          );
        })}
        <Route
          path="*"
          element={<NotFound title="Aradığınız Sayfa Bulunamadı" />}
        />
      </Routes>
      {!hideNavigationElements && <Footer />}
    </>
  );
}

export default App;
