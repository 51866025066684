import React from "react";
import { Helmet } from "react-helmet";

const page = [
  { pathName: "guvenlik", pageName: "Güvenlik" },
  function () {
    return (
      <div className="subPages_hizmetlerimiz-defaultCard DJI0061">
        <Helmet>
        <title>Kampüs Kreş - Güvenlik</title>
        <meta name="title" content="Kampüs Kreş - Güvenlik" />
        <meta property="og:title" content="Kampüs Kreş - Güvenlik" />
        <meta name="twitter:title" content="Kampüs Kreş - Güvenlik" />
      </Helmet>
        <div className="subPages_hizmetlerimiz-defaultCard_firstColumn">
          <p>
            Kreşimiz 24 saat boyunca 2 güvenlik personeli tarafından korunmakta,
            emniyet kökenli yönetici liderliğinde tümü emniyet müdürlüğünden
            sertifikalı güvenlik birimi tarafından denetlenmektedir. Bölgemize
            ait mobil güvenlik ekibimiz mevcut olup, tüm bölge ve okul çevresi
            24 saat güvenlik kameraları ile izlenmektedir.
          </p>
          <div
            className="img"
            style={{
              background: `url(${require("../../../../assets/guvenlik.png")})`,
            }}
          />
        </div>
        <div className="subPages_hizmetlerimiz-defaultCard_secondColumn">
          <p>
            Kreşimiz, 7 gün 24 saat uygulaması ile kesintisiz olarak
            izlenmektedir. Ziyaret ve iş için gelen misafirlerimize “Ziyaret
            Kart Sistemi” uygulanmakta ve ziyaretçilerimiz kayıt altına
            alınmaktadır. Binamızda güvenlik personelleri tarafından kontrol
            altında bulunan bir adet ana giriş ve iki adet bahçe giriş-çıkış
            kapısı mevcuttur. Binanın dışı, çevresi ve bahçesi; bina içinde ise
            koridorlar güvenlik birimince kamera sistemi ile izlenmektedir.
            Türkiye’nin 6 araçlı en modern itfaiye teşkilatı, Kreş binamızın
            yanında konumlanmış olup bölgemize hizmet vermektedir. 112 mobil
            aracı 24 saat acil durumlar için bölgemizde beklemekte, Organize
            Sanayi Bölgesi Ortak Sağlık Merkezimiz acil durumlarda müdahale
            etmektedir. Binamızdaki yangın sisteminin kurulması ve işleyişi,
            Organize Sanayi Bölge Müdürlüğü Yangın Güvenliği Uzmanımızın
            denetiminde yönetmeliğe uygun olarak kurulmuş, tüm okul çalışanları
            eğitimden geçirilmiştir. Belli aralıklarla tatbikatlar
            yapılmaktadır. Binamızda yangın algılama sistemi bulunmakta, tüm iç
            kapılar yangın yönetmeliğine uygun olarak koridor yönüne açılmakta
            olup, öğrencilere tehlike yaratmayacak şekilde niş içine alınmıştır.
            Kreşimizde 3 adet çıkış kapısı mevcuttur.
          </p>
        </div>
      </div>
    );
  },
];

export default page;
