import React from "react";
import { Helmet } from "react-helmet";
import { ReviewCard } from "../../../home/components/reviewCard";

const page = [
  { pathName: "veli-gorusleri", pageName: "Veli Görüşleri" },
  function () {
    const ErkekAvatar = require("../../../../assets/man.png");
    const KizAvatar = require("../../../../assets/girl.png");
    const BothAvatar = require("../../../../assets/man-girl.png");
    return (
      <div className="veliGorusleri">
        <Helmet>
          <title>Kampüs Kreş - Veli Görüşleri</title>
          <meta name="title" content="Kampüs Kreş - Veli Görüşleri" />
          <meta property="og:title" content="Kampüs Kreş - Veli Görüşleri" />
          <meta name="twitter:title" content="Kampüs Kreş - Veli Görüşleri" />

          <meta
            name="keywords"
            content="kreş yorumlar, yorumlar, kampüs kreş yorumları"
          />
        </Helmet>
        <ReviewCard fit avatar={KizAvatar} name="Burcu Aslan" date="2024">
          Biz oğlumuz Kuzey Aslan doğmadan önce okulunuzu öğrendik. Kuzey
          doğduğunda da başka hiçbir kreş aramadık. Okulunuzun iyi olduğunu
          arkadaşlarımızdan duymuştuk. Hayvan sevgisi ile de büyüyecek olması
          bizi çok sevindirmişti. Ancak bu kadarını beklemiyorduk.
          Öğretmenlerimiz bebeklerimize çok çok iyi bakıyor ve baktı. Oğlumuz
          bazı akşamlar bize gelmek değil kreşe dönmek bile istiyor.
          Öğretmenlerimiz çok ilgili, çok şefkatliler. Belgin Hanım için ise
          ayrıca anne şefkati ile yavrularımıza baktığını düşünüyor ve bir
          ebeveyn olarak oğlumu bırakıp işe dönerken gözümün arkada kalmadığını
          bildirmek istiyorum. Kimseye emanet edemediğim yavrumu size emanet
          edip gözümün arkada kalmadığına şaşırıyor yakınlarım. Her şey için
          teşekkürler... İlginize, özeninize, şefkatinize teşekkürler.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Pınar Dursun" date="2018">
          Ev sıcaklığında bir kreş. Her gün koşa koşa geldiği ve gitmek
          istemediği ikinci evi kızımızın. Biliyoruz ki burada kendini fark
          edecek ve en büyük önceliği kaliteli öğrenmek. Kampüslü olmak
          ayrıcalık ve daha 5 yaşındayken profesyonellikle tanışmaktır. Herkese
          teşekkürler.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="İlke Öndül" date="2018">
          Çocuğumdaki potansiyeli her alanda keşfedip açığa çıkardığınız ve daha
          da geliştirip, arttırdığınız için ve bunu alanında en iyi eğitimciler
          eşliğinde yaptığınız için sizlere minnettarız.
        </ReviewCard>
        <ReviewCard
          fit
          avatar={KizAvatar}
          name="Fatma Dilşad Bingöl"
          date="2018"
        >
          Öğretmenlerin ve personelin eğitim kalitesinden ve kişisel iletişim
          yeteneklerinden çok memnunum. Standart kreş veya anaokulunun çok
          ötesinde, çocukların sadece vakit geçirmek için gelmediği, kendilerini
          de geliştirdikleri kolej eğitimi veren bir kurum. Emeğinizden ve
          vizyonunuzdan dolayı sizleri tebrik ediyoruz. Sevgiler.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Özlem Özcan Akat" date="2018">
          Kampüs Kreş gibi okulları olan bir millerin istikbali emniyettedir.
          Her şey ama her şey için çok teşekkürler Kampüs Kreş Ailesi.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Neylan Pek" date="2018">
          Kampüs Kreş yönetimi olarak çok kurumsal ve profesyonel olduğunuzu
          düşünüyorum. Çocuğumu gönül rahatlığıyla gönderdiğimi söyleyebilirim.
          Öğretmenlerin yaklaşımı ve ilgisinin de gayet yerinde olduğunu
          gözlemliyorum. Teşekkür ederim böyle bir kurumu ülkemizde var
          ettiğiniz için.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Güldeste Varol" date="2018">
          Kampüs Kreş, eğitimi ile, öğretimi ile çocuklarımıza sunduğu olanaklar
          ile Türkiye'nin en iyi kreşi. Çocuklarımıza çocukluğunu doyasıya
          yaşatarak, eğlenerek öğrenme olanağı sunuyor. Kızım burada çok mutlu.
          Kızım burada çocukluğunu yaşayabiliyor. Emeği geçenlere sonsuz
          teşekkürler.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Semra Özgür" date="2018">
          Oğlumdaki olumlu yöndeki değişimi ben dahil tüm çevremiz fark etmiş
          durumda. Kampüs Kreş ile birlikte "Okul çok iyi geldi." cümlesini
          sıkça kullanıyor ve duyuyoruz. Her şeyden öte büyük istekle ve
          mutlulukla okula gidiyor.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Begüm Gözde Ertaş" date="2018">
          Eylül ayında başladığı ilk gün okula ağlayarak giden oğlum hasta olsa
          bile okula gitmek için sabırsızlanıyor. 6 ayda gerek sosyal gerek dil
          gelişimi, kas gelişiminde son derece gelişim gösterdi. Kampüs Kreş
          verdiğimiz en doğru kararlardan birisidir. Sınıf öğretmenlerimizin
          emeğine sağlık. Çok teşekkürler. İyi ki varsınız.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Beyhan Özalp" date="2018">
          Kampüs Kreş'i çok beğeniyorum. Keşke bende çocuk olsaydım dedirten bir
          okul. Okulun kalitesi her alanında belli oluyor. Sınıfımızdan ve
          öğretmenlerimizden çok memnunuz. İlgilerinden ötürü teşekkür ederiz.
        </ReviewCard>
        <ReviewCard fit avatar={ErkekAvatar} name="Selamet Yılmaz" date="2018">
          Kampüs Kreş olarak son derece çağdaş bir ortamda, etkin ve güler yüzlü
          personel ile beraber olmaktan memnuniyet duyuyoruz. Gönül rahatlığı
          ile çocuğumuzu teslim ediyoruz ve gelişimini izliyoruz.
          Başarılarınızın devamını diliyoruz.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Canan Köroğlu" date="2018">
          1 yaşından itibaren kreş bölümüne devam eden kızım için burası büyük
          bir şans. Bakım sağlayan öğretmenler çocukların her birine sevgiyle
          yaklaşıyorlar, onları tanıyorlar, her biri kendi çocuklarıymış gibi
          özel hissettiriyorlar. Öğle aralarında emzirmeye gittiğim için daha
          yakından gözlemleyebildim. Çok butik bir kreş. Minikler için
          tasarlanmış ve en ince detaylar bile düşünülmüş. Bebek bile olsalar
          eğitimi de önem veriliyor. Sadece vakit geçirmek değil, etkinlikler,
          şarkılar, oyunlar yapıyorlar. Bir annenin konuşamayan küçük bir bebeği
          bir yere emanet etmesi için gönlünün rahat ve içine sinmiş olması
          gerekir. Kampüs Kreş Ailesi bunu başarıyor.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Dilşad Bingöl" date="2017">
          Öncelikle okulumuzun mimarı yapısını ve donanımını çocuğumun gelişimi
          için yeterli buluyorum. Öğretmenlerimizin çocuğumuza ve bize karşı
          göstermiş oldukları iletişim dilini beğeniyorum. Daima bizimle
          işbirliği içinde ve destekleyiciler. Çocuğumuza karşı şefkatli ve
          özveriler.
        </ReviewCard>
        <ReviewCard fit avatar={ErkekAvatar} name="Gökhan Yıldız" date="2017">
          Çocuğumu gönül rahatlığı ile emanet ettiğim donanım ve fiziki
          imkanlarını çok beğendiğim bir okul. Tüm personelin görevini
          titizlikle yaptığı ve mesleklerinin erbabı kişiler olduğu her
          hallerinden belli.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Özlem Özcan Akat" date="2017">
          Çocuğumu güvenle emanet edebildiğim, çocuğuma anne şefkatiyle yaklaşan
          öğretmenlerin bir arada bulunduğu, bir çocuğun isteyebileceği tüm
          şeyleri bünyesinde barındıran, her geldiğimde keşke çocuk olsam
          dediğim bir okul Kampüs Kreş. Emeği geçen herkse sonsuz teşekkür.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Hatice Aydemir" date="2017">
          Çocukların özgürce hareket edebileceği iç ve dış bahçenin bulunduğu
          okulumuzu çok seviyoruz. Oğlum mutlu ve istekli geliyor. Başladığı
          günden itibaren kendine güvenen, isteklerini ifade edebilen bir
          ortamda. Öğretmenlerimiz sevgileri ve ilgileri çocukların okulu
          sevmesini sağlıyor. Emeği geçen öğretmenlerimize minnettarız. Çok
          seviyoruz.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Pınar Yeşilyurt" date="2017">
          Çocuklarımıza ilgi ve şefkatli yaklaşan Kampüs Kreş öğretmenlerine ve
          çalışanlarına teşekkürlerimizi sunuyoruz.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Esma Taşkaya" date="2017">
          Güler yüzlü ekibiniz, uzman kadronuzla nice uzun senelere Kampüs Kreş
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Neval Hopikoğlu" date="2017">
          Kızımızı kreşe göndermeden önce kafamızda çok fazla soru işareti
          vardı. Bir arkadaşımın tavsiyesiyle Kampüs Kreş'le tanıştık.
          Hayalimize en yakın yer burasıydı. Büyük katkılarından dolayı sınıf
          öğretmenlerimize çok teşekkür ederiz. İyi ki Kampüs Kreş Ailesine
          katılmışız.
        </ReviewCard>
        <ReviewCard fit avatar={ErkekAvatar} name="İsmihan Işık" date="2017">
          Sizlere çok teşekkür ederiz. Gönül rahatlığı ile gönderdiğim bir yer.
        </ReviewCard>
        <ReviewCard fit avatar={ErkekAvatar} name="Kadir Damar" date="2017">
          Kampüs Kreş çocuğumun yaşına uygun kaliteli zaman geçirebilmesi için
          çok güzel bir yer.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Tülay Coşkun" date="2017">
          İki yıldır kızımı güvenle gönderdiğim bir okul. Hastalandığında ya da
          özel bir durumda benim kadar ilgili. Öğretmenlerimize sevgimiz büyük.
          emeği geçen herkese teşekkürler.
        </ReviewCard>
        <ReviewCard fit avatar={ErkekAvatar} name="Fuat Kodaman" date="2017">
          Her şey için teşekkürler. Fiziksel ve sosyal alanlarından ve
          kadrosundan memnun olduğumuz bir kreş.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Jülide Gençer" date="2017">
          2 yıldır okulunuzda devam eden oğlumun gelişiminde çok önemli bir
          gelişim var. Bunun için teşekkür ederim. Aynı şekilde
          öğretmenlerimizden inanılmaz memnunuz ve desteklerinden dolayı
          kendilerine çok teşekkür ederiz. Bu arada bizi kapıda hep güler yüzlü
          ve saygılı karşılan ekibinize de çok teşekkür ederiz.
        </ReviewCard>
        <ReviewCard fit avatar={ErkekAvatar} name="Kıvanç Doğan" date="2017">
          Sevgili Kampüs Kreş Ailesi; İş yerimi yanı başında, çocuğumuzun
          gelişimini önemseyen sevgi dolu bir yuva sunduğunuz için teşekkürler.
        </ReviewCard>
        <ReviewCard fit avatar={ErkekAvatar} name="Bora Açıkgöz" date="2017">
          Bölgemizde çocuklarımızı güvenerek bırakabileceğimiz böyle bir kreş
          olduğu için çok şanslıyız. Emeği geçen herkese teşekkür ederim.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Elçin Esgin" date="2017">
          Oğlum için Kampüs Kreş öncesi ve sonrası oldu. Kendine güvenini
          kazanmasında, sosyalleşmesinde ve öz bakım becerilerinde gerçekten çok
          büyük katkı sağlamıştır. Böyle bir Kreş'i bize kazandıran başta OSB
          yönetimi ve Füsun Hanım'a sonsuz teşekkürler diliyorum. Ayrıca sınıf
          öğretmenlerimize de emeklerinden dolayı minnettarım.
        </ReviewCard>
        <ReviewCard
          fit
          avatar={KizAvatar}
          name="Melek Şeyma Çakmak"
          date="2017"
        >
          Sevgili Kampüs Kreş Ailesi; İyi ki varsınız. Çocuklarımızı emanet
          edebileceğimiz, sizin gibi bir kuruma ve öğretmenlere sahip olduğumuz
          için çok şanslıyız. Gerek kurumun kalitesi, gerekse öğretmenlerin ve
          personelin sıcak ve sevgi dolu yaklaşımı bizleri ve çocuklarımızı çok
          mutlu ediyor. Her şey için çok teşekkürler.
        </ReviewCard>
        <ReviewCard fit avatar={ErkekAvatar} name="Ziya Doruk" date="2017">
          Öncelikle ilkeli eğitim anlayışınız ve bilinçli, sorumlu birer yurttaş
          yetiştirme gayretinizden dolayı sonsuz teşekkürlerimizi sunmayı borç
          bilirim. İyi ki varsınız.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Esra Döker" date="2017">
          Kızımız okula öyle mutlu geliyor ki, anlıyoruz sevgiyle saygıyla
          eğitim alıyor. Kişisel gelişimi ve becerileri gözle görülür biçimde
          ilerleme gösteriyor. Ailecek sizlerle olmaktan çok mutluyuz.
          Danışmadaki arkadaşlardan başlayarak hepinize çok teşekkür ederiz.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Hatice Atasoy" date="2017">
          Okulunuzun sistemli çalışması, öğretmenlerinizin özverili olması bizi
          çok memnun ediyor. 1 sene boyunca öğretmenlerimizin çocuğumuza
          gösterdiği ilgi ve sevgisi bizi çok mutlu etti. Teşekkür ederiz.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Sinem Aktaş" date="2017">
          Kampüs Kreş: kızımın iki senedir devam ettiği, çok sevdiği, fiziki
          olanakları çok güzel olan okulumuz.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Özlem Yıldırım" date="2017">
          Oğlumuz 9 aylıkken başladı Kampüs'e. O günden beri içimiz çok rahat.
          Keşke ilk okul da açılsa.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Miray Şentürk" date="2017">
          Oğlumuz için en doğru okulu ararken Kampüs Kreş'i bulmamız şans oldu
          bizim için. Öğretmenlerimizle hem de arkadaşlarımızla çok güzel bir
          yıl geçirdik. Güzel anılarla hatırlayacağız okulumuzu.
        </ReviewCard>
        <ReviewCard fit avatar={ErkekAvatar} name="Arda Sümerli" date="2017">
          Okulumuzu vermiş olduğu eğitimden ve topluma kendine güveni tam
          bireyler yetiştirmeye gayret gösterdiği için çok seviyoruz.
          Öğretmenlerimizin çocuğumuza göstermiş olduğu ilgi ve destekten ötürü
          teşekkür ederiz. En kısa zamanda ilkokul açılmasını bekliyoruz.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Nevin Köksu" date="2017">
          Okulumuzu görür görmez işte burası diyebildiğimiz bir okul. İlk okul
          deneyimimiz bu okulda oldu. Bahçesi, ışık alabilen kapalı oyun alanı,
          akıllı bina oluşu, her yerin ahşap oluşu okulu seçmeme vesile oldu.
          Öğretmenlerimizle okul bütünleşti ve her alanda ihtiyacımı karşılayan
          bir okul oldu. Burası okul değil çocuklar için hazırlanmış bir tesis.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Hülya Yıldırım" date="2017">
          Oğlum üç yaş grubu öğrencisi. Benim beklentilerimi fazlasıyla
          karşılayan bir okul. Öğretmenlerimizin ve branş öğretmenlerimizin
          desteğiyle oğlumuzun okul hayatı çok olumlu başlamış oldu. Her gün
          okula yeni bir heyecanla geliyor. Okulun yaz ve kış bahçelerinin
          olması çok büyük bir avantaj. Çocuklarımızı oyuna doyuran bir okul.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Ayşegül Desovalı" date="2017">
          Kızımıza göstermiş olduğunuz ilgiden ve gelişimine sağladınız katkıdan
          dolayı başta sınıf öğretmenlerimiz olmak üzere diğer tüm Kampüs Kreş
          öğretmenlerine ve çalışanlarına teşekkür ederiz.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Eda Bayramoğlu" date="2017">
          Oğlumu gönül rahatlığı ile bıraktığım güvenilir, sevgi dolu eğitim
          yuvası. O kadar mutlu ve rahatlamış hissediyorum ki anlatamam. Çünkü
          oğlum için doğru, verimli, güvenilir bir eğitim kurumu keşfettim.
          Öğretmenlerimizin gösterdiği sabır, sevgi dolu eğitim vermeleri
          nedeniyle binlerce kez teşekkür ederim. Sizlerle mutlu, gelişerek
          öğrenerek büyüyen oğlum çok mutlu, o mutluyken ben de mutluyum. İyi ki
          varsınız. Muhteşem bir ekip. Sayenizde ben de iş hayatıma dönebildim.
          Sizlerle beraber nice güzel yıllara İnşallah. Sevgiler.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Emanuela Ceylan" date="2017">
          Kızım her gün eve geldiğinde yeni bir şeyler öğrenmiş geliyor. bu da
          beni çok mutlu ediyor. Öğretmenlerini çok seviyor. Her şey için
          teşekkür ederim.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Zeynep Mert" date="2017">
          Her güm güler yüzlü bir şekilde karşılanmak çok güzel pozitif bir
          enerji hissediyorum. Teşekkürler.
        </ReviewCard>
        <ReviewCard
          fit
          avatar={KizAvatar}
          name="Hatice Değirmenci Alkan"
          date="2017"
        >
          Okulumuzun eğitim alanı çok geniş ve güzel. Eğitmenler çok profesyonel
          ve sıcakkanlılar. Özellikle okulunuzun kapısından içeri girdiğimiz
          zaman bizi karşılayan ekip arkadaşlarınız inanılmaz ilgili ve güler
          yüzlü. Kendilerine çok teşekkür ederim.
        </ReviewCard>
        <ReviewCard
          fit
          avatar={KizAvatar}
          name="Hülya Ertek Kurşun"
          date="2017"
        >
          Çocuğumuzu huzurlu bir şekilde emanet edebileceğiniz bir okul, bir ev,
          bir aile... Sonsuz teşekkürler.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Selda Şahin" date="2017">
          Okulumuzdan çok memnunuz. Çocuğumda her konuda ilerlemeler olduğunu
          söyleyebilirim. Okula mutlu gidip mutlu dönüyoruz. Her şey için
          teşekkürler.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Gözde Geniş" date="2017">
          Her yönü ile tüm beklentilerimizi karşılamış bir okul. Oğlumdaki
          olumlu gelişimleri deneyimlemek harika. Öğretmenimizin ve okul
          yönetiminin ilgi ve alakası çok güzel. Her şey için çok teşekkürler.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Zehra Belhan" date="2017">
          Kocaman bir aile gibi Kampüs Kreş yaşantısı. Çocuğun bizden çok okulda
          arkadaşları ve öğretmenleri ile vakit geçiriyor. Bu vaktin kalitesi ve
          mutlu ediciliğiyle ilgili en ufak bir kuşkum yok. Öğretmenlerimiz çok
          sevimli ve bilgili.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Aslı Yorgancı" date="2017">
          Kampüs Kreş, evladımızın zorlu bir hayata başlamadan önceki ilk
          adımıydı bizler için ve biz burada olmaktan çok mutluyuz. Eğitim
          kalitesi, eğitimleri, yöneticileri ile her zaman farkını yaratan ve
          hep böyle olacağına inancımın sonsuz olduğu bir okul. En önemlisi;
          Atatürkçü, demokratik ve hümanist, işinin ehli eğitmen ve
          yöneticilerin olması . Tebrik ve teşekkürlerimi sunarım.
        </ReviewCard>
        <ReviewCard
          fit
          avatar={KizAvatar}
          name="Zülfiye Düztaş Özer"
          date="2017"
        >
          Oğlumuzun sizin için bir notu var: Aktivitelerden, oyunlardan mutlu
          oluyorum, sizi seviyorum. Oğlumun kendi düşüncesini yazdım buraya.
          Onun mutlu olduğu, öğretmenlerin sevgisini yürekten hissettiği ve
          onları yürekten sevdiği, sevgi dolu bir kurum. İyi ki varsınız!
          Çocuğumuzu iki yıl boyunca bir anne şefkatiyle sevgisiyle emeğini
          eksik etmeyen,tüm öğretmenlerimize çok ama çok teşekkür ederim. İyi ki
          varsın Kampüs Kreş. OSB yönetimine, büyük emek harcayan Kreş
          yönetimine çok teşekkür ederiz.
        </ReviewCard>
        <ReviewCard
          fit
          avatar={KizAvatar}
          name="Ayşegül Tığlı Tekin"
          date="2017"
        >
          Kampüs Kreş'ten çok memnunum. Kızımızın sosyal gelişimine çok katkı
          sağlayan bir okul oldu bizim için. Öğretmenlerin ilgisi ve çocuklarla
          olan iletişimi de çok güçlü. Modern eğitim tarzı, okulun fiziksel
          imkanları da çocukların sosyal, duygusal, fiziksel gelişimleri için
          çok etkili bir öğrenme ortamı sunuyor. Teşekkürler.
        </ReviewCard>
        <ReviewCard fit avatar={ErkekAvatar} name="Hakan İşbilen" date="2017">
          İki senedir Kampüs Kreş'teyiz. Bu süre içinde herhangi bir problemle
          karşılaşmadık. Verilen hizmetten gayet memnun kaldık. Çocuğumuzda
          olumlu gelişmeler gözlemledik.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Ş. Ayhan Kutlu" date="2017">
          Kampüs Kreş için Türkiye'nin en büyük kreşi olduğunu duymuş
          olabilirsiniz. Bu büyüklük sahip olduğu alandan ziyade sahip oldukları
          ve çocuklarımıza da aşıladıkları değerler ile de ilgilidir. Bizim
          nesil kreş kelimesinden çok yuva kelimesini kullanmıştır. Kızımız
          burada ikinci yuvasında iken gönlümüz son derece rahat. Değişimi ve
          gelişimi gördükçe tercihimizin ne kadar doğru olduğunu anlıyoruz.
          Sağlam temeller üzerine kurulu bu yuvanın nice nesilleri yetiştirmesi
          ve güzel bir gelecek umuduyla...
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Ayça Oral" date="2017">
          Sevgili Kampüs Kreş, bu yıl sizlerle son senemiz. 3 yıl birlikte
          harika zamanlar geçirdik. Sizlere daha altı bezliyken ve tam olarak
          konuşamıyorken teslim ettiğim canım oğlum bugün kendine güvenen, ben
          yaparım diyebilen, hayal gücü gelişmiş, dil eğitimi aşırı ilerlemiş
          bir birey olarak ilk okula başlamaya hazır. Her öğretmenimize ayrı
          ayrı kattığı bütün değerler, öğretimler, güzellikler için size sonsuz
          teşekkür ederiz. Güvenimizi boşa çıkarmadınız, iyi ki varsınız.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Yasemin Uzun" date="2017">
          Bu yıl Hazırlık Sınıfındayız ve bu 4. yılımız. Okulumuzdan ve
          öğretmenlerimizden memnuniyetimiz o kadar fazla ki bu yıl gideceği
          ilkokulun hazırlık sınıfına göndermek bile istemedik. Mezun olacağı
          için çok üzgünüz. Çocuğumuzu bir fidan gibi özenle büyütmemize
          yardımcı olduğunuz için çok çok teşekkür ederiz. İyi ki varsınız.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Hande Enez" date="2017">
          Okulumuzdan çok memnunuz. Oğlum her geçen gün kendini geliştirdiğini
          gözüyor ve çok mutlu oluyorum. Her şey için teşekkürler Kampüs Kreş.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Pınar Saltık" date="2017">
          2,5 senedir Kampüs Kreş'ten hep çok memnun kaldık. Öğretmenler ve
          diğer tüm personel her zaman güler yüzlü ve çok ilgiliydi. Okulun bu
          2,5 senede kızımızın gelişimine çok katkısı oldu. Her şey için Kampüs
          Kreş Ailesine çok teşekkür ederiz.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Oya Sarıkan" date="2017">
          Kampüs Kreş'te her şey çok özel, çok düzenli ve çok yapıcı.
          Öğretmenlerin yaklaşımı, ve önerileri her zaman çok faydalı oldu.
          Kampüs'ün içindekiler, küçük kulübe ve oyun alanı insanda oyun oynama
          isteği yaratıyor. Kendimizi hep özel hissediyoruz.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Nuriye Altınkaya" date="2017">
          2,5 yaşındaki oğlumu, mecburen okula kayıt ettirmek durumunda
          kaldığımızda kafamda pek çok soru, kaygı, düşünce olmuştu. Çok mu
          erkendi? Biraz daha mı büyüse? Ama Eylül itibariyle okula
          başladığımızda daha ilk haftadan okulun ve öğretmenlerin bu konudaki
          tecrübesi, uzmanlığı, sabrı, sevgisi bizim için kurtarıcı oldu.
          Adaptasyon dönemini hızla atlattık ve oğlumuzu her gün çok mutlu
          alıyoruz okuldan. Çok çok memnunuz!
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Pelin Doğan" date="2017">
          Atatürkçü bir eğitim anlayışıyla, güler yüzlü ekibinizle gönlümde bir
          numara eğitim yerisiniz. Öğretmenlerimizin çocuklarımıza olan
          sevgileri gözlerinde alev alev. Okula gelirken bizi karşılan
          danışmadaki arkadaşlarınız, güvenliğiniz harika. Sizlerle olduğumuz
          için çok şanslıyız. Okulunuz ve ekibiniz iyi ki varsınız.
        </ReviewCard>
        <ReviewCard
          fit
          avatar={BothAvatar}
          name="Asya-Hamdi Kırkımcı"
          date="2017"
        >
          Sadece İstanbul'da değil, Türkiye genelinde bu kalitede bir başka okul
          öncesi eğitim kurumu görmediğimizi belirtmek isterim. Çocuğumuzun her
          yönden gelişimini destekleyen tüm olanakları sunan okulumuzdan çok
          memnunuz. 2 yıldır kızımız bu okulda ve 2 yıl boyunca katettiği yol,
          gösterdiği gelişim bizi ciddi anlamda şaşırtmakta ve mutlu etmekte.
          bundan en büyük katkı sınıf öğretmenlerimize ait. Her daim dikkatle
          gözlemde bulunmakta, bize geri bildirimleri ile destek olmaktalar. Hem
          Zehra'nın hem de bizlerin çok şanslı olduğunu ifade etmek isteriz.
          Okula, yönetime ve tabii sınıf öğretmenlerimize çok çok teşekkür
          ederiz.
        </ReviewCard>
        <ReviewCard fit avatar={ErkekAvatar} name="Hüsnü Kızılkaya" date="2017">
          Kampüs Kreş, okulun fiziksel koşulları, müfredatı, eğitim araç ve
          gereçleri, veli iletişimi ve değerli öğretmenleri ile beklentilerimizi
          karşılayan bir okul oldu. Başta öğretmenlerimiz olmak üzere emeği
          geçen herkese teşekkür ederiz.
        </ReviewCard>
        <ReviewCard
          fit
          avatar={KizAvatar}
          name="Merve Kocaaslan Güneş"
          date="2017"
        >
          Ozan'a 2 yaş itibariyle kattıklarınız çok fazla. İlk sene anne
          şefkatini yoğun olarak yaşattınız. Bu sene ise anne şefkati +
          disiplini eşit değerde sunuyorsunuz. Önümüzdeki yıla da baya büyük
          adımlarla ilerleyeceğimize inancım sonsuz. Teşekkürler.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Esra Pekcan" date="2017">
          Çok güzel bir okul. Çocuğumu böyle bir kuruma teslim ettiğim için
          kendimizi şanslı hissediyoruz. Emeği geçen tüm çalışanlara teşekkür
          ediyorum.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Canan Kılıç" date="2017">
          Oğlumun keyifle vakit geçirdiği, yeni birçok şey öğrendiği çok güzel
          bir okul. Öğretmenleri inanılmaz ilgili, temizliğe dikkat ediliyor,
          çalışanlar güler yüzlü. Okulumuzdan çok memnunuz.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Gülay Hatip" date="2017">
          Oğlum Poyraz 20 aylıkken Kampüs Kreş Ailesine katıldı. Şuan 3,5
          yaşındayız ve ilk günden itibaren burda oğlum kendisini evinde gibi
          hissetti bende hiç gözüm arkada kalmadan rahatça işime devam
          edebildim. Oğluma kattıklarından dolayı Kampüs Kreş Ailesine
          teşekkürlerimizi sunarım.
        </ReviewCard>
        <ReviewCard fit avatar={ErkekAvatar} name="Abdullah Vural" date="2017">
          2 yılı aşkın süredir Kampüs Kreş'e devam etmekteyiz. Kesinlikle doğru
          tercih yaptığımızı düşünüyorum. Çocuğumuzun gelişimi, kendini ev
          sıcaklığında hissetmesi, sağlıklı iletişim kurması vb. birçok noktada
          Kampüs Kreş Ailesinini büyük desteğini gördük. Her şey için çok
          teşekkür ederiz.
        </ReviewCard>
        <ReviewCard fit avatar={ErkekAvatar} name="Uğur Yenier" date="2017">
          Kampüs Kreş'te evlatlarımız sıcak, modern ve gelişimlerine sonsuz
          katkı sağlaya bir ortamda yetişiyor. Eğitim ve öğretim olarak
          yaşıtlarının önünde, mutlu ve değerlerimize bağlı olarak büyüyorlar.
          Başta değerli öğretmenler olmak üzere emeği geçen tüm personele ve
          özellikle yönetime teşekkür ederiz.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Emine Yolcu" date="2017">
          Kampüs Kreş ailesinde 4. yılımız. Oğlum 2 yaşında başladı ve şuan
          anasınıfında. Oğlumu bu okula göndermekle en doğru kararı verdiğimizi
          her sene hatta her gün çok daha iyi anlıyoruz. Gerek öğretmen kadrosu,
          gerek okul yönetimi ve gerekse okulun fiziksel şartları ve eğitim
          sistemi kusursuz. Oğlum her sabah okula çok mutlu gidip mutlu dönüyor
          ve birçok şey öğrendi. Bu okulda olduğumuz için kendimizi çok ama çok
          şanslı hissediyorum. Tüm öğretmenlerimize, yönetime ve bu okulun
          kurulmasında emeği geçen herkese teşekkürler.
        </ReviewCard>
        <ReviewCard
          fit
          avatar={BothAvatar}
          name="Zehra-Onur Kökmen"
          date="2017"
        >
          Öncelikle bu kadar deneyimli, özverili ve sevgi dolu bir okulumuz
          olduğu için kendimi ne kadar şanslı hissettiğimizi bilmenizi isteriz.
          Çocuğumuz Atatürk sevgisi ile büyümesine fırsat tanıdığınız için
          ayrıca minnettarız. Tüm olanakları ile son derece memnunuz. Gözümüz
          arkada kalmadan güvenli bir ikinci evimiz oldunuz. Yazacak çok şey
          var, kelimelere sığdırmak mümkün değil.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Neriman Göktekin" date="2017">
          Sizi çok çok seviyor, emekleriniz sabrınız sonsuz sevginiz için
          minnettar olduğumuzu bilmenizi istiyoruz.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Nevin Köz Demir" date="2017">
          Sevgili Kampüs Kreş Ailesi; Çocuklarımıza sevgiyle bilgiyle
          donattığınız onları geleceğe hazırladığınız için sonsuz teşekkürler.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Fatma Yücekan" date="2017">
          Çocuğumun Kampüs Kreş'te başlamasından beri oldukça geliştiğini
          görebiliyorum. Profesyonel eğitim kadrosu ile çocuklara doğru eğitim
          verildiğini düşünüyorum. Bu nedenle çocuğumuzun doğru yerde olduğunu
          düşünüyoruz. İçimiz çok rahat.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Yelda Yıldırım" date="2017">
          Okulunuzun kızıma kattıkları gözle görülür bir şekilde herkesçe fark
          ediliyor. Yaşıtlarına göre oldukça ileride gelişim gösteriyor. Bunun
          sebebinin sadece öğrenciden kaynaklandığını değil eğitim sisteminiz
          olduğunu düşünüyorum. Ben Atatürkçü zihniyetiniz, eğitim sisteminiz,
          eğitimcilerinizin ilgi ve alakasından dolayı son derece memnunum.
          Diğer kurumlardan farklı olan bina dizaynınızı ayrıca taktir ediyoruz.
          Kızımı gönlüm rahat gönderiyorum ve herkese tavsiye ediyorum.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Gülsen Ayık" date="2016">
          Hergün güler yüzlü, yaptığı işi seven, bir o kadar da disiplinli,
          gerçekten samimi bir ekibin sizi ve çocuğunuzu karşıladığını düşünün.
          Kızımı gönül rahatlığıyla bıraktığım bir kreş. Aslında kreşten öte .
          İyi ki sizleri tanımışım...
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Seda Ertorun" date="2016">
          Yeni okulumuzda ilk toplantımızda Kaan'ın pozitifliği kişisel gelişimi
          kendi kendi kendine yetebilmesi ile ilgili birçok övgü aldık. Bunların
          hepsinde biz anne babası kadar -hatta zaman zaman bizden de fazla
          -sizlerin de büyük katkınız ve emeğiniz var. Sizlere çok çok teşekkür
          ediyorum. Kaan'ı mutlaka Kampüs Kreş"e getirmek istiyorum okula
          sizleri görmesi ve hasret gidermesi için..
        </ReviewCard>
        <ReviewCard fit avatar={ErkekAvatar} name="Ayhan Gök" date="2016">
          Çok iyi düşünülmüş titizlikle inşa edilmiş bir Kreş, güler yüzlü ve
          deneyimli bir kadro, güvenli, huzurlu ve sağlıklı bir mekan. Biz
          ailece çok memnunuz. Önünüzdeki nice yıllara başarılarla dileriz.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Zerrin Yazıcı" date="2016">
          Öncelikle okulun yapı olarak güvenli olması benim için çok önemliydi.
          İkinci olarak öğretim kadronuz ve bu kadronun öğrenciler üzerinde
          bırakmış olduğu etkiydi. Bu sorularıma kreşinizde rahatlıkla cevap
          buldum. Öğrenciler hakkında velilerin detaylı bir şekilde
          bilgilendirilmeleri de önemli bir faktör. Tabiki oyun alanları,
          aktiviteleri, beceri alanlarının geniş açılı olması da benim için
          önemli, son olarak kreşinizde ilgili bir psikolog ve hemşirenizin
          olması da ayrıca bir artı sebebidir.
        </ReviewCard>
        <ReviewCard
          fit
          avatar={ErkekAvatar}
          name="Ertuğrul Babaoğlu"
          date="2016"
        >
          Temelinden itibaren kreş olarak tasarlanmış binada lavabo ebatları,
          priz seçimi, oda büyüklükleri, her etkinlik için özel olarak
          tasarlanmış odaları, aydınlatması, sağlıklı bir şekilde ısınması gibi
          birçok detay düşünülmüş. Böyle bir binayı profesyonel bir yönetim ve
          profesyonel olduğu kadar güler yüzlü bir eğitim kadrosuyla
          tamamlayarak kurulan kreşe çocuğumuzu huzur içinde bırakabiliyoruz.
          Çocuğumuzun akşamları eve mutlu geldiğini görmek bizi de sonra derece
          mutlu ediyor.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Dilek Yavuz" date="2016">
          Ben kızımı altı ay önce okulunuza gönderdim ve bu altı ay içinde
          kızımın çok olumlu yönde değişimini gördüm. Bu nedenle okulunuzun
          altyapısını ve verdiği eğitiminden çok memnun kaldım. Gerek
          öğretmenlerimiz, gerekse okulumuz çok sıcak ve güvenilir. Çocuğumu
          güvenilir, huzurlu bir okula göndermenin mutluluğu içindeyiz.
          Öğretmenlerimizin her çocukla ayrı ayrı ilgilenmesi ayrı ayrı özel
          olduklarını hissettirmesi bizi çok mutlu ediyor. Benim kızım şimdiden
          çok şanslı böyle güvenilir, böyle ilgi öğretmenleri olduğu için. Bütün
          öğretmenlerimize ayrı ayrı teşekkür ediyorum.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Elif Uygur" date="2016">
          Taşınma sonrası evimize yakın kreş arayışımızın sonrasında, okulun
          ferah, ortamı, yeni ve temiz ekipmanlar, güvenli bir ortam, yönetici
          kadronun ve öğretmenlerin deneyimi, güleryüzü, tecrübesi, geniş oyun
          alanı, titizlikle hazırlanmış eğitim sistemi, ve Atatürk’çü olması
          sizi tercih etmemizi sağladı.
        </ReviewCard>
        <ReviewCard
          fit
          avatar={BothAvatar}
          name="Atife Aksoy Alpaslan & Özgür Alpaslan"
          date="2016"
        >
          Mutfak etkinlikleri ve güzel yemekler tüm yaş gruplarının birlikte
          zaman geçirebildiği geniş ferah oyun alanları, işinin hakkını veren
          güler yüzlü öğretmenler ve kreş çalışanları, deneyerek öğrenme
          ortamları ve çocuğumuzun psikolojik gelişimiyle ilgili aldığımız
          destekten çok memnunuz. Ayrıca çocuğumuzun müzik, İngilizce ve
          bilgisayar gibi branş derslerine iyi bir başlangıç yaptığını
          düşünüyoruz.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Özlem Doğan" date="2016">
          Kampüs Kreş’in bütün öğretmen ve personeline çocuğumuz için
          gösterdikleri ilgi ve alakadan dolayı teşekkür ederim. Okulun
          güvenliği ilk sırada önem verdiğim faktördü. Okulun güvenliğinden
          gayet memnunum. Her açıdan güvenli geldi. Öğretmenlerimizin alakası
          aile ile işbirliği içinde olması çok hoşuma gidiyor. Ben her gün bilgi
          veren gün içinde ne yaptığını bildiren görüş kağıdı da hoşuma giden
          ayrıntıdır. Bence çocuğunu bu kreşe göndermek isteyen herkes gönül
          rahatlığıyla gönderebilir. Hepinize ayrı ayrı teşekkürler. En önemlisi
          psikologun verdiği bilgi ile ailenin daha bilinçli olduğu kesin. Bu
          gerçekten çocuklar ve velileri için çok güzel bir şey.
        </ReviewCard>
        <ReviewCard
          fit
          avatar={ErkekAvatar}
          name="Muharrem Tokdemir"
          date="2016"
        >
          Okulun alanının geniş olması çok güzel. Yakında havuzun açılacak
          olması çocuklar açısından çok güzel bir fırsat. Bahçede çeşitli
          hayvanların olması çocukları mutlu edebilir. Okul yönetimi ve
          öğretmenlerimiz hem velilere (bizim izlemimiz elbette) hem de
          çocuğumuza oldukça ilgili davranmakta. Okulun yeni açılmış olması, hem
          kullanılan eşyaların yeniliği, hem de güvenlik açısından olumlu. Bizim
          sizi seçmemizin ilk nedeni okulun işyerimize yakın olması. Diğeri ise
          okulu hem web sitesinden hem de gelip yerinde görmemizdir. Eğitim
          sisteminizle ilgili farklılıkları da çocuğumuzun zaman içerisinde
          gösterdiği olumlu değişim ve gelişimlerle görmekteyiz. Baba Gazanfer
          Uğurlu Her şeyi ile çok iyi tasarlanmış ferah bir mekan. Belinay çok
          eğlendiğini söylüyor. Eğlenirken öğrendiğini de biz gözlemliyoruz. O
          da mutlu biz de…
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Çiğdem Dündar" date="2016">
          Gün ışığını gören oyun alanları, serbestçe bisiklete binebilme
          özgürlüğü, (bu arada şimdi alanın pek değişmesini istemiyorum, gün
          ışığında ferah ferah bisiklete binebiliyor diye) Öğretmeni ile
          ilişkisi, kapıda bizi karşılayan bayandan görevliye kadar güler yüzlü
          ortam, öğretmeni ile arasındaki sevgisi, her daim hemşire olması,
          oyuncak çeşitliliği, güvenli olduğunu hissetmemiz, sorunlara
          yaklaşımınız ve hemen çözümlemeniz çocuğumuzu size emanet etmemizde
          temel faktörler olarak sayılabilir.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Fatma Fettahoğlu" date="2016">
          Kampüs Kreş’e ilk geldiğimde ilk güveni psikolog Ilgın Hanım’da
          hissettim. İçeride çok fazla gezinmeden ortamdaki huzuru hissettim ve
          kızımın da bunu hissedeceğini düşündüm. Kreşin yakınlığı, alanının çok
          büyük oluşu, öğretmenlerin güler yüzlü olması, profesyonellikleri,
          kreşte kızıma sunulan fırsatlar, aldığı eğitim… Herşey çok güzel, bunu
          hissedebiliyorum. Okula başlayalı çok kısa bir zaman olsa bile
          gelişimi oldukça güzel gidiyor. Öğretmenlerin dışında diğer
          çevresindekilerin de davranışları çok güzel. Karakter gelişimi
          açısından çok önemli buluyorum. Sizlerin sayesinde iletişim ve görsel
          estetik açısından da gelişimi fark ediliyor. Bir istek: Keşke kızımı
          webcam’den görebilsem. Hoş olurdu. Her şey için herkese teşekkürle
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Selin D.Yatgın" date="2016">
          Çocuğunuz için tam ona göre bir yer hayal edin. İşte burası o hayal
          ettiğiniz yer. İlgili öğretmenleri ve personeliyle, çocuğumuzun mutlu
          ve güvende olması için tasarlanmış yapısıyla hayallerimizin
          gerçekleştiği bir yer.
        </ReviewCard>
        <ReviewCard
          fit
          avatar={KizAvatar}
          name="Ulviye Melek Şengül"
          date="2016"
        >
          Çocuğum sizin sayenizde sosyalleşti, yetenekleri sizinle gelişti ve
          arttı. Her konuda profosyonel olan ekibinize güveniyorum.
        </ReviewCard>
        <ReviewCard
          fit
          avatar={KizAvatar}
          name="Güleser Sedef Çaylı"
          date="2016"
        >
          Kampüs Kreş’i tercih etmemizin nedeni güvenli huzur dolu bir aile gibi
          olması, gerek öğretmenler, gerekse okul, geniş oyun parkı, kış bahçesi
          ve sınıfları ile harikulade, her şey bir anne gibi düşünülmüş. Kreş’e
          başarılarının devamını diliyoruz.
        </ReviewCard>
        <ReviewCard fit avatar={KizAvatar} name="Elif Yılmaz" date="2016">
          Sare 11 aylıkken Kampüs Kreş’e başladı. Küçük olması nedeniyle
          tereddütler yaşadık. Fakat kendi yaş grubu ile ayrı bir bölümde
          olması, öğretmen sayısının yeterli olması ve çocuklarla birebir
          ilgilenilmesi, gün içindeki yaşam alanının geniş ve ferah olması, gün
          içinde ilgisizlikten sıkılma ihtimalinin olmaması, sağlık durumlarının
          ve yemeklerinin kontrol ediliyor olması, tereddütlerimizi ortadan
          kaldırdı. Güvenli bir ortamda kalacağından emin olduk. Tüm
          çalışanların güler yüzlü olması ve her konuda yardımcı olmaya
          çalışmaları ayrıca memnuniyet verici.
        </ReviewCard>
      </div>
    );
  },
];

export default page;
