import React from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import {
  getRegistryForms,
  getFormDetails,
  getRegistryForm,
} from "../../../graphql/queries";
import {
  deleteRegistryFormMutation,
  toggleQuotaMutation,
} from "../../../graphql/mutations";

import "./styles.forms.css";

import osbResolver from "./resolvers/osbResolver.json";
import genderResolver from "./resolvers/genderResolver.json";
import classResolver from "./resolvers/classResolver.json";
import foundAtResolver from "./resolvers/foundAtResolver.json";

import { jsPDF } from "jspdf";
import * as XLSX from "xlsx";

import { calcAge } from "../../../tools";
import {
  CloseIcon,
  DateIcon,
  DownloadIcon,
  SpinnerIcon,
} from "../../../assets/icons";
import { calcAgeYear } from "../../../tools/calculateAgeYear";
import { NotFound } from "../../../components/NotFound";
import { sortArrayUsingID } from "../../../tools/sortArray";
import { Helmet } from "react-helmet";

function ExportXlsxModal({ json, quotas, docRef, handleClose }) {
  const [educationPeriod, setEducationPeriod] = React.useState(null);

  const downloadXlsxDocument = () => {
    let type = parseInt(educationPeriod) + 1;

    let forms = json
      .filter((form) => {
        if (type === 33) return true;
        return parseInt(form.birth.split("$")[1]) === type;
      })
      .map((form) => {
        let fields = form.textFields.concat(
          form.booleanFields.concat(form.dateFields)
        );
        return {
          _id: form._id,
          Ogrenci: form.name,
          Cinsiyet: genderResolver[form.gender - 1],
          "Dogum Tarihi": form.birth.split("$")[0],
          "Egitim Donemi": `${
            form.birth.split("$")[1] === "1"
              ? "0 - 1"
              : form.birth.split("$")[1]
          } Yaş`,
          "Sinif Kategorisi": classResolver[form.classType - 1],
          Ebeveyn: form.applicator.name,
          "Yakinlik Derecesi": form.applicator.degreeOfCloseness,
          "OSB'de Calisma Durumu": form.applicator.inOSB
            ? "OSB'de Calisiyor"
            : "OSB'de Calismiyor",
          "Telefon Numarasi": form.applicator.phoneNumber,
          Email: form.applicator.email,
          Iletisim: form.birth
            .split("$")[2]
            .split(".")
            .map((item, i) => {
              var val = "";

              switch (i) {
                case 0:
                  if (item === "1") {
                    val = "TEL";
                  }
                  break;
                case 1:
                  if (item === "1") {
                    val = "MAIL";
                  }
                  break;
                case 2:
                  if (item === "1") {
                    val = "SMS";
                  }
                  break;
                default:
                  break;
              }

              return val;
            })
            .join(", "),
          "Nereden Buldundu":
            foundAtResolver[Number(form.birth.split("$")[3]) - 1],
          ...fields.map((field) => {
            return field.input;
          }),
        };
      });

    forms.forEach((form, formIndex) => {
      let fields = json[0].textFields.concat(
        json[0].booleanFields.concat(json[0].dateFields)
      );

      if (fields.length > 0) {
        fields.forEach((field, i) => {
          delete Object.assign(forms[formIndex], {
            [field.fieldName]: forms[formIndex][i],
          })[i];
        });
      }
    });

    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(forms);

    XLSX.utils.book_append_sheet(wb, ws, `KampusKresFormlari`);

    XLSX.writeFile(wb, "KampusKresFormlari.xlsx");
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Kampüs Kreş - Admin</title>
        <meta name="title" content="Kampüs Kreş - Admin" />
        <meta property="og:title" content="Kampüs Kreş - Admin" />
        <meta name="twitter:title" content="Kampüs Kreş - Admin" />
      </Helmet>
      <div className={`modal${docRef ? " active" : " inactive"}`}>
        <div className="modalHeaderWrap">
          <h1 className="modalTitle">Tüm Formları Excel Dosyasına Çıkart</h1>
          <button className="modalCloseButton" onClick={() => handleClose()}>
            <CloseIcon />
          </button>
        </div>
        <div className="selectForm">
          {quotas
            .sort(function (a, b) {
              return a._id - b._id;
            })
            .map((quota, i) => (
              <div className="quotaSelectButton" key={quota._id.toString()}>
                <input
                  className="selectInput"
                  value={quota._id.toString()}
                  type="radio"
                  name="educationPeriod"
                  id={`eduper_${i}`}
                  checked={educationPeriod === quota._id.toString()}
                  onChange={(e) => setEducationPeriod(e.currentTarget.value)}
                />
                <label className="selectLabel" htmlFor={`eduper_${i}`}>
                  {quota._id === 0
                    ? `${calcAgeYear(1)} - ${calcAgeYear(0)}`
                    : calcAgeYear(parseInt(quota.ageRange))}
                </label>
              </div>
            ))}
          <div className="quotaSelectButton">
            <input
              className="selectInput"
              value="32"
              type="radio"
              name="educationPeriod"
              id={`eduper_32`}
              checked={educationPeriod === "32"}
              onChange={(e) => setEducationPeriod(e.currentTarget.value)}
            />
            <label className="selectLabel" htmlFor={`eduper_32`}>
              Hepsi
            </label>
          </div>
        </div>
        <button
          className={educationPeriod ? "active" : "disabled"}
          disabled={educationPeriod ? false : true}
          onClick={() => (educationPeriod ? downloadXlsxDocument() : null)}
        >
          Excel Dosyasını Çıkart
        </button>
      </div>
      <div
        onClick={() => handleClose()}
        className={`modalBg${docRef ? " active" : " inactive"}`}
      ></div>
    </React.Fragment>
  );
}

export function FormsPage({ params, adminType }) {
  const { loading, data, refetch } = useQuery(getRegistryForms, {
    variables: {
      session: window.localStorage.getItem("session"),
    },
  });
  const {
    loading: loadingQuota,
    data: dataQuota,
    refetch: refetchQuota,
  } = useQuery(getFormDetails);
  let [
    getSingleForm,
    {
      loading: loadingForm,
      data: dataForm,
      refetch: refetchForm,
      called: calledSingleForm,
    },
  ] = useLazyQuery(getRegistryForm, {
    variables: {
      session: window.localStorage.getItem("session"),
    },
  });
  const [deleteRegistryForm] = useMutation(deleteRegistryFormMutation);
  const [toggleQuotaParent] = useMutation(toggleQuotaMutation);

  const [formDeleteProcess, setFormDeleteProcess] = React.useState({});
  const [quotaToggleProcess, setQuotaToggleProcess] = React.useState({});
  const [outputing, setOutputing] = React.useState(false);
  const [xlsxModalOn, setXlsxModalOn] = React.useState(false);

  const toEnglishCharset = function (text) {
    return text
      .replace(/ı/g, "i")
      .replace(/İ/g, "I")
      .replace(/ü/g, "ü")
      .replace(/Ü/g, "Ü")
      .replace(/ğ/g, "g")
      .replace(/Ğ/g, "G")
      .replace(/ş/g, "s")
      .replace(/Ş/g, "Ş")
      .replace(/ç/g, "c")
      .replace(/Ç/g, "C")
      .replace(/ö/g, "o")
      .replace(/Ö/g, "O");
  };

  if (loading || loadingQuota || loadingForm)
    return (
      <div
        style={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
          top: "35%",
          left: "50%",
        }}
        className="loading"
      >
        <SpinnerIcon />
        <p>Sayfa Yükleniyor</p>
      </div>
    );

  const downloadPdfDocument = (e, rootElementId, formId, student) => {
    e.preventDefault();
    let doc = new jsPDF("portrait", "pt", "A4");
    doc.html(
      document.getElementById(rootElementId),
      {
        callback: () => {
          doc.save(`${student}_${formId}.pdf`);
        },
      },
      {
        language: "tr",
      }
    );
    setTimeout(() => {
      setOutputing(false);
    }, 500);
  };

  const toggleQuota = async (_id) => {
    try {
      setQuotaToggleProcess({
        _id,
        status: "processing",
        statusMessage: "Durum İşleniyor...",
      });
      const config = await toggleQuotaParent({
        variables: { _id, session: window.localStorage.getItem("session") },
      });
      if (config) {
        setTimeout(() => {
          refetchQuota();
          setQuotaToggleProcess({});
        }, 1500);
      }
    } catch (err) {
      setQuotaToggleProcess({
        _id,
        status: "error",
        statusMessage: "Durum İşlenemedi",
      });
    }
  };

  const deleteForm = async (_id) => {
    try {
      setFormDeleteProcess({
        _id,
        status: "processing",
        statusMessage: "Form Siliniyor...",
      });
      const config = await deleteRegistryForm({
        variables: { _id, session: window.localStorage.getItem("session") },
      });
      if (config) {
        setTimeout(() => {
          setFormDeleteProcess({
            _id,
            status: "confirmed",
            statusMessage: "Form Silindi",
          });
          setTimeout(() => {
            refetch();
            if (calledSingleForm) refetchForm();
          }, 1000);
        }, 1500);
      }
    } catch (err) {
      setFormDeleteProcess({
        _id,
        status: "errored",
        statusMessage: "Form Silinemedi",
      });
    }
  };

  let formFind = params.get("form");
  if (formFind && !calledSingleForm)
    getSingleForm({
      variables: {
        _id: parseInt(formFind),
        session: window.localStorage.getItem("session"),
      },
    });
  if (calledSingleForm) {
    dataForm = dataForm.getRegistryForm;

    if (dataForm === null) {
      return <NotFound title="Aradığınız Form Bulunamadı" />;
    }

    return (
      <div className="forms">
        <Helmet>
          <title>Kampüs Kreş - Admin</title>
          <meta name="title" content="Kampüs Kreş - Admin" />
          <meta property="og:title" content="Kampüs Kreş - Admin" />
          <meta name="twitter:title" content="Kampüs Kreş - Admin" />
        </Helmet>
        <div className="form">
          <div className="pdfOut" id="pdfOut">
            <div className="formHeading">
              <h1 className="formTitle">
                {outputing ? toEnglishCharset(dataForm.name) : dataForm.name}
              </h1>
              <span className="formId">Form ID: #{dataForm._id}</span>
            </div>
            <div className="formValuesGrid">
              <div className="formValueWrapper">
                <h3>
                  {outputing ? toEnglishCharset("Cinsiyet:") : "Cinsiyet:"}
                </h3>
                <div className="formValue">
                  {outputing
                    ? toEnglishCharset(genderResolver[dataForm.gender - 1])
                    : genderResolver[dataForm.gender - 1]}
                </div>
              </div>
              <div className="formValueWrapper">
                <h3>
                  {outputing ? toEnglishCharset("Doğum Günü:") : "Doğum Günü:"}
                </h3>
                <div className="formValue">
                  {dataForm.birth.split("$")[0]}{" "}
                  <span>
                    <DateIcon />
                    {outputing
                      ? toEnglishCharset(
                          calcAge(dataForm.birth.split("$")[0]).format
                        )
                      : calcAge(dataForm.birth.split("$")[0]).format}
                  </span>
                </div>
              </div>
              <div className="formValueWrapper">
                <h3>
                  {outputing ? toEnglishCharset("Sınıf Tipi:") : "Sınıf Tipi:"}
                </h3>
                <div className="formValue">
                  {outputing
                    ? toEnglishCharset(classResolver[dataForm.classType - 1])
                    : classResolver[dataForm.classType - 1]}
                </div>
              </div>
              <div className="formValueWrapper">
                <h3>
                  {outputing
                    ? toEnglishCharset("Öğretim Dönemi:")
                    : "Öğretim Dönemi:"}
                </h3>
                <div className="formValue">
                  {dataForm.birth.split("$")[1] === "1"
                    ? "0 - 1"
                    : dataForm.birth.split("$")[1]}{" "}
                  {outputing ? toEnglishCharset("Yaş") : "Yaş"}
                </div>
              </div>
            </div>
            <div className="applicatorFormValues">
              <h2>
                {outputing
                  ? toEnglishCharset("Formu Dolduran Kişinin Bilgileri:")
                  : "Formu Dolduran Kişinin Bilgileri:"}
              </h2>
              <div className="formValueWrapper">
                <h3>
                  {outputing ? toEnglishCharset("Ad Soyad:") : "Ad Soyad:"}
                </h3>
                <div className="formValue">
                  {outputing
                    ? toEnglishCharset(dataForm.applicator.name)
                    : dataForm.applicator.name}
                </div>
              </div>
              <div className="formValueWrapper">
                <h3>
                  {outputing
                    ? toEnglishCharset("Yakınlık Derecesi:")
                    : "Yakınlık Derecesi:"}
                </h3>
                <div className="formValue">
                  {outputing
                    ? toEnglishCharset(dataForm.applicator.degreeOfCloseness)
                    : dataForm.applicator.degreeOfCloseness}
                </div>
              </div>
              <div className="formValueWrapper">
                <h3>
                  {outputing
                    ? toEnglishCharset("OSB'de Çalışma Durumu:")
                    : "OSB'de Çalışma Durumu:"}
                </h3>
                <div className="formValue">
                  {outputing
                    ? toEnglishCharset(
                        osbResolver[dataForm.applicator.inOSB ? 0 : 1]
                      )
                    : osbResolver[dataForm.applicator.inOSB ? 0 : 1]}
                </div>
              </div>
              <div className="formValueWrapper">
                <h3>
                  {outputing
                    ? toEnglishCharset("Telefon Numarası:")
                    : "Telefon Numarası:"}
                </h3>
                <div className="formValue">
                  {outputing
                    ? toEnglishCharset(dataForm.applicator.phoneNumber)
                    : dataForm.applicator.phoneNumber}
                </div>
              </div>
              <div className="formValueWrapper">
                <h3>{outputing ? toEnglishCharset("E-Mail:") : "E-Mail:"}</h3>
                <div className="formValue">
                  {outputing
                    ? toEnglishCharset(dataForm.applicator.email)
                    : dataForm.applicator.email}
                </div>
              </div>
            </div>
            {dataForm.birth.split("$")[3] && (
              <div className="formValueWrapper">
                <h3>Bizi nereden buldu:</h3>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingInline: "50px",
                  }}
                  className="formValue"
                >
                  <p>
                    {outputing
                      ? toEnglishCharset(
                          foundAtResolver[
                            Number(dataForm.birth.split("$")[3]) - 1
                          ]
                        )
                      : foundAtResolver[
                          Number(dataForm.birth.split("$")[3]) - 1
                        ]}
                  </p>
                </div>
              </div>
            )}
            <div className="formValueWrapper">
              <h3>
                {outputing
                  ? toEnglishCharset("İletişim Seçenekleri")
                  : "İletişim Seçenekleri"}
                :
              </h3>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingInline: "50px",
                }}
                className="formValue"
              >
                <p>
                  {dataForm.birth.split("$")[2].split(".")[0] === "1"
                    ? "✅ Telefon"
                    : "❌ Telefon"}
                </p>
                <p>
                  {dataForm.birth.split("$")[2].split(".")[1] === "1"
                    ? "✅ Mail"
                    : "❌ Mail"}
                </p>
                <p>
                  {dataForm.birth.split("$")[2].split(".")[2] === "1"
                    ? "✅ SMS"
                    : "❌ SMS"}
                </p>
              </div>
            </div>
            <div className="formValues">
              {dataForm.textFields.map((field, i) => (
                <div key={i} className="formValueWrapper">
                  <h3>
                    {outputing
                      ? toEnglishCharset(field.fieldName)
                      : field.fieldName}
                    :
                  </h3>
                  <div className="formValue">
                    {outputing ? toEnglishCharset(field.input) : field.input}
                  </div>
                </div>
              ))}
              {dataForm.dateFields.map((field, i) => (
                <div key={i} className="formValueWrapper">
                  <h3>
                    {outputing
                      ? toEnglishCharset(field.fieldName)
                      : field.fieldName}
                    :
                  </h3>
                  <div className="formValue">
                    {outputing ? toEnglishCharset(field.input) : field.input}
                  </div>
                </div>
              ))}
              {dataForm.booleanFields.map((field, i) => (
                <div key={i} className="formValueWrapper">
                  <h3>
                    {outputing
                      ? toEnglishCharset(field.fieldName)
                      : field.fieldName}
                    :
                  </h3>
                  <div className="formValue">
                    {JSON.parse(field.input) ? "✅" : "❌"}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="formButtonWrap">
            <button
              style={adminType === "ALP" ? {} : { display: "none" }}
              onClick={() => deleteForm(dataForm._id)}
              className={`deleteButton ${
                formDeleteProcess._id === dataForm._id
                  ? formDeleteProcess.status
                  : "default"
              }`}
              type="submit"
            >
              {formDeleteProcess._id === dataForm._id
                ? formDeleteProcess.statusMessage
                : `#${dataForm._id} ID'li Formu Sil`}
            </button>
            <div className="downloadButtonsWrap">
              <button
                onClick={(e) => {
                  setOutputing(true);
                  downloadPdfDocument(e, "pdfOut", dataForm._id, dataForm.name);
                }}
                className="pdfIn"
              >
                <DownloadIcon />
                Formu PDF Olarak İndir
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="forms">
      <ExportXlsxModal
        json={data.getRegistryForms}
        quotas={dataQuota.getQuotas}
        handleClose={() => setXlsxModalOn(false)}
        docRef={xlsxModalOn}
      />
      <div
        style={adminType === "ALP" ? {} : { display: "none" }}
        className="quotaWrapper"
      >
        {sortArrayUsingID([...dataQuota.getQuotas]).map((quota, i) => (
          <button
            key={i}
            onClick={() => toggleQuota(quota._id)}
            className={`quotaButton ${
              quotaToggleProcess._id === quota._id
                ? quotaToggleProcess.status
                : quota.openQuota
                ? "default"
                : "closed"
            }`}
            type="button"
          >
            {quotaToggleProcess._id === quota._id
              ? quotaToggleProcess.statusMessage
              : quota._id === 0
              ? `${calcAgeYear(1)} - ${calcAgeYear(0)}`
              : calcAgeYear(parseInt(quota.ageRange))}
          </button>
        ))}
      </div>
      <button
        style={data.getRegistryForms.length === 0 ? { display: "none" } : {}}
        onClick={() => setXlsxModalOn(true)}
        className="modalOpenButtonXlsx"
      >
        Tüm Formları Excel Dosyasına Çıkart
      </button>
      {data.getRegistryForms.length < 1 ? (
        <NotFound title="Görüntülenecek Form Yok" />
      ) : (
        data.getRegistryForms.map((form, i) => (
          <div key={i} className="form">
            <div className="pdfOut" id="pdfOut">
              <div className="formHeading">
                <h1 className="formTitle">
                  {outputing ? toEnglishCharset(form.name) : form.name}
                </h1>
                <span className="formId">Form ID: #{form._id}</span>
              </div>
              <div className="formValuesGrid">
                <div className="formValueWrapper">
                  <h3>
                    {outputing ? toEnglishCharset("Cinsiyet:") : "Cinsiyet:"}
                  </h3>
                  <div className="formValue">
                    {outputing
                      ? toEnglishCharset(genderResolver[form.gender - 1])
                      : genderResolver[form.gender - 1]}
                  </div>
                </div>
                <div className="formValueWrapper">
                  <h3>
                    {outputing
                      ? toEnglishCharset("Doğum Günü:")
                      : "Doğum Günü:"}
                  </h3>
                  <div className="formValue">
                    {form.birth.split("$")[0]}{" "}
                    <span>
                      <DateIcon />
                      {outputing
                        ? toEnglishCharset(
                            calcAge(form.birth.split("$")[0]).format
                          )
                        : calcAge(form.birth.split("$")[0]).format}
                    </span>
                  </div>
                </div>
                <div className="formValueWrapper">
                  <h3>
                    {outputing
                      ? toEnglishCharset("Sınıf Tipi:")
                      : "Sınıf Tipi:"}
                  </h3>
                  <div className="formValue">
                    {outputing
                      ? toEnglishCharset(classResolver[form.classType - 1])
                      : classResolver[form.classType - 1]}
                  </div>
                </div>
                <div className="formValueWrapper">
                  <h3>
                    {outputing
                      ? toEnglishCharset("Öğretim Dönemi:")
                      : "Öğretim Dönemi:"}
                  </h3>
                  <div className="formValue">
                    {form.birth.split("$")[1] === "1"
                      ? "0 - 1"
                      : form.birth.split("$")[1]}{" "}
                    {outputing ? toEnglishCharset("Yaş") : "Yaş"}
                  </div>
                </div>
              </div>
              <div className="applicatorFormValues">
                <h2>
                  {outputing
                    ? toEnglishCharset("Formu Dolduran Kişinin Bilgileri:")
                    : "Formu Dolduran Kişinin Bilgileri:"}
                </h2>
                <div className="formValueWrapper">
                  <h3>
                    {outputing ? toEnglishCharset("Ad Soyad:") : "Ad Soyad:"}
                  </h3>
                  <div className="formValue">
                    {outputing
                      ? toEnglishCharset(form.applicator.name)
                      : form.applicator.name}
                  </div>
                </div>
                <div className="formValueWrapper">
                  <h3>
                    {outputing
                      ? toEnglishCharset("Yakınlık Derecesi:")
                      : "Yakınlık Derecesi:"}
                  </h3>
                  <div className="formValue">
                    {outputing
                      ? toEnglishCharset(form.applicator.degreeOfCloseness)
                      : form.applicator.degreeOfCloseness}
                  </div>
                </div>
                <div className="formValueWrapper">
                  <h3>
                    {outputing
                      ? toEnglishCharset("OSB'de Çalışma Durumu:")
                      : "OSB'de Çalışma Durumu:"}
                  </h3>
                  <div className="formValue">
                    {outputing
                      ? toEnglishCharset(
                          osbResolver[form.applicator.inOSB ? 0 : 1]
                        )
                      : osbResolver[form.applicator.inOSB ? 0 : 1]}
                  </div>
                </div>
                <div className="formValueWrapper">
                  <h3>
                    {outputing
                      ? toEnglishCharset("Telefon Numarası:")
                      : "Telefon Numarası:"}
                  </h3>
                  <div className="formValue">
                    {outputing
                      ? toEnglishCharset(form.applicator.phoneNumber)
                      : form.applicator.phoneNumber}
                  </div>
                </div>
                <div className="formValueWrapper">
                  <h3>{outputing ? toEnglishCharset("E-Mail:") : "E-Mail:"}</h3>
                  <div className="formValue">
                    {outputing
                      ? toEnglishCharset(form.applicator.email)
                      : form.applicator.email}
                  </div>
                </div>
              </div>
              <div className="formValues">
                {form.birth.split("$")[3] && (
                  <div className="formValueWrapper">
                    <h3>Bizi nereden buldu:</h3>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingInline: "50px",
                      }}
                      className="formValue"
                    >
                      <p>
                        {outputing
                          ? toEnglishCharset(
                              foundAtResolver[
                                Number(form.birth.split("$")[3]) - 1
                              ]
                            )
                          : foundAtResolver[
                              Number(form.birth.split("$")[3]) - 1
                            ]}
                      </p>
                    </div>
                  </div>
                )}
                <div className="formValueWrapper">
                  <h3>
                    {outputing
                      ? toEnglishCharset("İletişim Seçenekleri")
                      : "İletişim Seçenekleri"}
                    :
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingInline: "50px",
                    }}
                    className="formValue"
                  >
                    <p>
                      {outputing
                        ? form.birth.split("$")[2].split(".")[0] === "1"
                          ? "EVET Telefon"
                          : "HAYIR Telefon"
                        : form.birth.split("$")[2].split(".")[0] === "1"
                        ? "✅ Telefon"
                        : "❌ Telefon"}
                    </p>
                    <p>
                      {outputing
                        ? form.birth.split("$")[2].split(".")[1] === "1"
                          ? "EVET Mail"
                          : "HAYIR Mail"
                        : form.birth.split("$")[2].split(".")[1] === "1"
                        ? "✅ Mail"
                        : "❌ Mail"}
                    </p>
                    <p>
                      {outputing
                        ? form.birth.split("$")[2].split(".")[2] === "1"
                          ? "EVET SMS"
                          : "HAYIR SMS"
                        : form.birth.split("$")[2].split(".")[2] === "1"
                        ? "✅ SMS"
                        : "❌ SMS"}
                    </p>
                  </div>
                </div>
                {form.textFields.map((field, i) => (
                  <div key={i} className="formValueWrapper">
                    <h3>
                      {outputing
                        ? toEnglishCharset(field.fieldName)
                        : field.fieldName}
                      :
                    </h3>
                    <div className="formValue">
                      {outputing ? toEnglishCharset(field.input) : field.input}
                    </div>
                  </div>
                ))}
                {form.dateFields.map((field, i) => (
                  <div key={i} className="formValueWrapper">
                    <h3>
                      {outputing
                        ? toEnglishCharset(field.fieldName)
                        : field.fieldName}
                      :
                    </h3>
                    <div className="formValue">
                      {outputing ? toEnglishCharset(field.input) : field.input}
                    </div>
                  </div>
                ))}
                {form.booleanFields.map((field, i) => (
                  <div key={i} className="formValueWrapper">
                    <h3>
                      {outputing
                        ? toEnglishCharset(field.fieldName)
                        : field.fieldName}
                      :
                    </h3>
                    <div className="formValue">
                      {JSON.parse(field.input) ? "✅" : "❌"}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="formButtonWrap">
              <button
                style={adminType === "ALP" ? {} : { display: "none" }}
                onClick={() => deleteForm(form._id)}
                className={`deleteButton ${
                  formDeleteProcess._id === form._id
                    ? formDeleteProcess.status
                    : "default"
                }`}
                type="submit"
              >
                {formDeleteProcess._id === form._id
                  ? formDeleteProcess.statusMessage
                  : `#${form._id} ID'li Formu Sil`}
              </button>
              <div className="downloadButtonsWrap">
                <button
                  onClick={(e) => {
                    setOutputing(true);
                    downloadPdfDocument(e, "pdfOut", form._id, form.name);
                  }}
                  className="pdfIn"
                >
                  <DownloadIcon />
                  Formu PDF Olarak İndir
                </button>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}
