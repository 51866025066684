import { useQuery } from "@apollo/client";
import React from "react";
import { ImageIcon, SpinnerIcon } from "../../../assets/icons";
import { getImages } from "../../../graphql/queries";
import { toUrlSafe } from "../../../tools/urlSafeFormat";
import axios from "axios";

import "./styles.gallery.admin.css";
import { Helmet } from "react-helmet";

export function AdminGallery({ adminType }) {
  const {
    data: imageData,
    loading: imageLoading,
    refetch,
  } = useQuery(getImages);

  const [image, setImage] = React.useState({ title: "" });
  const [statusMessage, setStatusMessage] = React.useState("");
  const [currentImage, setCurrentImage] = React.useState(null);
  const [currentViewType, setCurrentViewType] = React.useState(1);

  async function fileUploadHandler(imageFile, options) {
    const fd = new FormData();
    fd.append("options", JSON.stringify({ saveToGallery: true }));
    for (const key in options) {
      if (key !== "file") {
        fd.append(key, options[key] || "");
      }
    }
    fd.append("session", window.localStorage.getItem("session"));
    fd.append("file", imageFile, imageFile.name);

    await axios
      .post(`${process.env.REACT_APP_API_HOST}/images/image/upload`, fd, {
        onUploadProgress: (progressEvent) => {
          setStatusMessage(
            "Fotoğraf yükleniyor... " +
              Math.round((progressEvent.loaded / progressEvent.total) * 100) +
              "%"
          );
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setStatusMessage("Fotoğraf yüklendi.");
          if (currentViewType === options.type) {
            refetch({ type: currentViewType || 1 });
          }
        } else {
          setStatusMessage("Fotoğraf yüklenirken hata oluştu.");
        }
      })
      .catch((err) => {
        console.log(err);
        setStatusMessage("Fotoğraf yüklenirken hata oluştu.");
      });
  }

  function multipleFileUploadHandler(imageFiles, options) {
    for (let i = 0; i < imageFiles.length; i++) {
      fileUploadHandler(imageFiles[i], options);
    }
  }

  function fileDeleteHandler(_id) {
    axios
      .delete(`${process.env.REACT_APP_API_HOST}/images/image/${_id}`, {
        data: {
          session: window.localStorage.getItem("session"),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          refetch({ type: currentViewType || 1 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  React.useEffect(() => {
    if (
      statusMessage === "Fotoğraf yüklendi." ||
      statusMessage === "Fotoğraf yüklenirken hata oluştu."
    ) {
      setImage({ title: "" });
      setTimeout(() => {
        setStatusMessage("");
      }, 2500);
    }
  }, [statusMessage]);

  function getIDFromURL(url) {
    const splitSlash = url.split("/");
    const fileNameWithExtension = splitSlash[splitSlash.length - 1];
    const fileID = fileNameWithExtension.split(".")[0];

    return fileID;
  }

  if (currentImage) {
    return (
      <div onClick={() => setCurrentImage(null)} className="imageDisplay">
        <img alt={toUrlSafe(currentImage.title)} src={currentImage.location} />
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>Kampüs Kreş - Admin</title>
        <meta name="title" content="Kampüs Kreş - Admin" />
        <meta property="og:title" content="Kampüs Kreş - Admin" />
        <meta name="twitter:title" content="Kampüs Kreş - Admin" />
      </Helmet>
      <div className="admin_form_wrap">
        <div className="admin_image_dragDrop">
          <span>{image?.files ? "Fotoğraf Değiştir" : "Fotoğraf Seç"}</span>
          <p>
            {image?.files
              ? image?.files.length > 1
                ? "Birden Fazla Fotograf Secildi"
                : image.files[0].name
              : "veya Buraya Sürükle"}
          </p>
          <input
            multiple={adminType === "ALP"}
            onChange={(e) => {
              setImage((oldObj) => ({
                ...oldObj,
                files: e.target.files,
              }));
              if (!e.target.files[0]) {
                setImage((oldObj) => ({
                  ...oldObj,
                  files: undefined,
                }));
              }
            }}
            accept="image/png, image/jpeg, image/webp"
            type="file"
          />
        </div>
        <div className="admin_inputWrap">
          <label htmlFor="imageDescription">
            Fotoğraf Açıklaması{" "}
            <span style={{ fontSize: "0.8em" }}>(opsiyonel)</span>
          </label>
          <input
            id="imageDescription"
            onChange={(e) =>
              setImage((oldObj) => ({
                ...oldObj,
                title: e.target.value,
              }))
            }
            placeholder="Resim için bir açıklama..."
            type="text"
            value={image.title}
          />
        </div>
        <div className="admin_gallery_radioWrap">
          <div className="admin_gallery_radio">
            <input
              name="imageType"
              type="radio"
              id="1"
              onChange={(e) =>
                setImage((oldObj) => ({
                  ...oldObj,
                  type: parseInt(e.target.value),
                }))
              }
              value="1"
              checked={image.type === 1}
            />
            <label htmlFor="1">Okulda Yaşam</label>
          </div>
          <div className="admin_gallery_radio">
            <input
              onChange={(e) =>
                setImage((oldObj) => ({
                  ...oldObj,
                  type: parseInt(e.target.value),
                }))
              }
              name="imageType"
              type="radio"
              id="2"
              value="2"
              checked={image.type === 2}
            />
            <label htmlFor="2">Kreş</label>
          </div>
          <div className="admin_gallery_radio">
            <input
              onChange={(e) =>
                setImage((oldObj) => ({
                  ...oldObj,
                  type: parseInt(e.target.value),
                }))
              }
              name="imageType"
              type="radio"
              id="3"
              value="3"
              checked={image.type === 3}
            />
            <label htmlFor="3">Yaz Okulu</label>
          </div>
          <div className="admin_gallery_radio">
            <input
              onChange={(e) =>
                setImage((oldObj) => ({
                  ...oldObj,
                  type: parseInt(e.target.value),
                }))
              }
              name="imageType"
              type="radio"
              id="4"
              value="4"
              checked={image.type === 4}
            />
            <label htmlFor="4">Binamız</label>
          </div>
          <div className="admin_gallery_radio">
            <input
              onChange={(e) =>
                setImage((oldObj) => ({
                  ...oldObj,
                  type: parseInt(e.target.value),
                }))
              }
              name="imageType"
              type="radio"
              id="5"
              value="5"
              checked={image.type === 5}
            />
            <label htmlFor="5">Çiftliğimiz</label>
          </div>
          <div className="admin_gallery_radio">
            <input
              onChange={(e) =>
                setImage((oldObj) => ({
                  ...oldObj,
                  type: parseInt(e.target.value),
                }))
              }
              name="imageType"
              type="radio"
              id="6"
              value="6"
              checked={image.type === 6}
            />
            <label htmlFor="6">Gezilerimiz</label>
          </div>
          <div className="admin_gallery_radio">
            <input
              onChange={(e) =>
                setImage((oldObj) => ({
                  ...oldObj,
                  type: parseInt(e.target.value),
                }))
              }
              name="imageType"
              type="radio"
              id="7"
              value="7"
              checked={image.type === 7}
            />
            <label htmlFor="7">Törenler ve Kutlamalarımız</label>
          </div>
        </div>
        <span>{statusMessage}</span>
        <button
          className="admin_save_button"
          style={
            image && image.files && image.type
              ? { cursor: "pointer" }
              : { opacity: ".5", cursor: "not-allowed" }
          }
          disabled={!image || !image.files || !image.type}
          onClick={() => {
            if (image && image.files && image.type) {
              let options = {
                type: image.type,
                title: image.title,
              };

              if (image.files.length > 1 && adminType === "ALP") {
                multipleFileUploadHandler(image.files, options);
              } else {
                fileUploadHandler(image.files[0], options);
              }
            }
          }}
        >
          Fotoğrafı Yükle
        </button>
      </div>
      <table className="data_table">
        <tbody>
          <tr>
            <th>Fotoğraf</th>
            <th>ID</th>
            <th>
              <select
                onChange={(e) => {
                  setCurrentViewType(parseInt(e.target.value));
                  refetch({ type: parseInt(e.target.value) });
                }}
              >
                <option value="1">Okulda Yaşam</option>
                <option value="2">Kreş</option>
                <option value="3">Yaz Okulu</option>
                <option value="4">Binamız</option>
                <option value="5">Çiftliğimiz</option>
                <option value="6">Gezilerimiz</option>
                <option value="7">Törenler ve Kutlamalarımız</option>
              </select>
            </th>
          </tr>
        </tbody>
        {imageLoading && (
          <tbody>
            <tr>
              <td
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="loading">
                  <SpinnerIcon />
                  <p>Fotoğraflar Yükleniyor</p>
                </div>
              </td>
            </tr>
          </tbody>
        )}
        {!imageLoading &&
          imageData.getImages.map((dataImage, i) => (
            <tbody>
              <tr key={i}>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setCurrentImage({
                      title: dataImage.displayName,
                      location: dataImage.location,
                    })
                  }
                >
                  <ImageIcon />
                  <p>Görüntüle</p>
                </td>
                <td>{getIDFromURL(dataImage.location)}</td>
                <td>
                  <button onClick={() => fileDeleteHandler(dataImage._id)}>
                    Fotoğrafı Sil
                  </button>
                </td>
              </tr>
            </tbody>
          ))}
      </table>
    </div>
  );
}
