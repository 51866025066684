import React from "react";
import logo from "../../assets/compressed/logo.webp";

import {
  FacebookIcon,
  InstagramIcon,
  YoutubeIcon,
  LinkedInIcon,
  MenuIcon,
  EducationIcon,
  ToycarIcon,
  ImageIcon,
  DocumentsIcon,
  NewspaperIcon,
  PhoneIcon,
  DocumentIcon,
} from "../../assets/icons";

import "./style.nav.css";

import { ReactComponent as AdButton } from "../../assets/adbutton.svg";
import { ReactComponent as NewVidButton } from "../../assets/gozumden-kampus.svg";

export function NavBar() {
  const [on, setOn] = React.useState(false);

  return (
    <React.Fragment>
      {on && (
        <React.Fragment>
          <div className="navPlaceHolder" />
          <div className="mobileMenu">
            <a className="mobileNavigationItem blue" href="/okulumuz">
              <EducationIcon />
              Okulumuz
            </a>
            <a className="mobileNavigationItem brand" href="/hizmetlerimiz">
              <ToycarIcon />
              Hizmetlerimiz
            </a>
            <a className="mobileNavigationItem green" href="/galeri">
              <ImageIcon />
              Galeri
            </a>
            <a className="mobileNavigationItem magenta" href="/makaleler">
              <DocumentsIcon />
              Makaleler
            </a>
            <a className="mobileNavigationItem purple" href="/basinda-biz">
              <NewspaperIcon />
              Basında Biz
            </a>
            <a
              href="/#contact"
              onClick={() => {
                setOn((oldState) => !oldState);
              }}
              style={{ outline: "none", border: "none", cursor: "pointer" }}
              className="mobileNavigationItem pink"
            >
              <PhoneIcon />
              İletişim
            </a>
            <a
              className="mobileNavigationItem white"
              href="/ogrencibasvuru?v=2"
            >
              <DocumentIcon />
              Öğrenci Başvuru Formu
            </a>
          </div>
          <div
            onClick={() => setOn((oldState) => !oldState)}
            className="menuBg"
          />
        </React.Fragment>
      )}
      <div className="navWrap" target="_blank">
        {/* <a rel="noreferrer" href="https://youtu.be/TKOuhfH9xYQ">
          <AdButton />
        </a> */}
        <a
          rel="noreferrer"
          href="https://youtu.be/TxcSQRkSx9k?si=YUwDu1kLQMLzhnvg"
        >
          <NewVidButton />
        </a>
        <a href={process.env.REACT_APP_HOST}>
          <img className="logoFloat" src={logo} alt="kampuskres logo" />
        </a>
        <MenuIcon
          className="menuButton"
          onClick={() => setOn((oldState) => !oldState)}
        />
        <div className="socialWrap">
          <a
            href="https://www.linkedin.com/company/kampüs-kreş"
            rel="noreferrer"
            target="_blank"
          >
            <LinkedInIcon width="30px" />
          </a>
          <a
            href="https://www.facebook.com/kampuskres/"
            rel="noreferrer"
            target="_blank"
          >
            <FacebookIcon width="30px" />
          </a>
          <a
            href="https://www.instagram.com/kampuskres/"
            rel="noreferrer"
            target="_blank"
          >
            <InstagramIcon width="30px" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCCCfHNQy0H8CFwmTIpOw_lw"
            rel="noreferrer"
            target="_blank"
          >
            <YoutubeIcon width="30px" />
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}
