import React from "react";
import { Helmet } from "react-helmet";

const page = [
  { pathName: "tatil-uygulamasi", pageName: "Tatil Uygulaması" },
  function () {
    return (
      <ul style={{ listStyle: "numeric" }}>
        <Helmet>
        <title>Kampüs Kreş - Tatil Uygulaması</title>
        <meta name="title" content="Kampüs Kreş - Tatil Uygulaması" />
        <meta property="og:title" content="Kampüs Kreş - Tatil Uygulaması" />
        <meta name="twitter:title" content="Kampüs Kreş - Tatil Uygulaması" />
      </Helmet>
        <li>Kreşimiz tüm resmi ve dini bayramlarda tatildir.</li>
        <li>
          Eğitim - Öğretim döneminde İstanbul Valiliği'nin belirlediği tatiller
          uygulanır.
        </li>
        <li>
          Her yıl MEB tarafından belirlenen Sömestr tatilinin ilk haftası
          okulumuz kapalıdır.
        </li>
        <li>
          Her yıl MEB tarafından belirlenen yaz tatilinin, önceden duyurulan iki
          haftasında okulumuz kapalıdır.
        </li>
      </ul>
    );
  },
];

export default page;
