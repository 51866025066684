export function toUrlSafe(item) {
  return item
    .toLowerCase()
    .replace(/ı/g, "i")
    .replace(/ü/g, "ü")
    .replace(/ğ/g, "g")
    .replace(/ş/g, "s")
    .replace(/ç/g, "c")
    .replace(/ö/g, "o")
    .replace(/\s/g, "-");
}
