import React from "react";
import { Helmet } from "react-helmet";

const page = [
  { pathName: "binamiz", pageName: "Binamız" },
  function () {
    return (
      <div className="subPage_okulumuz-infoCard_wrap">
        <Helmet>
        <title>Kampüs Kreş - Binamız</title>
        <meta name="title" content="Kampüs Kreş - Binamız" />
        <meta property="og:title" content="Kampüs Kreş - Binamız" />
        <meta name="twitter:title" content="Kampüs Kreş - Binamız" />
      </Helmet>
        <div className="subPage_okulumuz-infoCard">
          <img
            alt="Okulumuz"
            src={require("../../../../assets/compressed/DJI_0061.webp")}
          />
          <div className="subPage_okulumuz-infoCard_textWrap">
            <h3>Okulumuz</h3>
            <p>
              5.000 m2 kullanım alanına sahip kreşimiz, Deprem Yönetmeliği’ne
              uygun olarak projelendirilmiş, yapı sistemi betonarme karkas
              olarak seçilmiş ve B 420 c sismik demir ve C35 cinsi beton
              kullanılmıştır.
              <br />
              <br />
              Bodrum kat, giriş kat, sınıfların bulunduğu kat ve idari katlardan
              oluşan binamızda üç adet merdiven ve bir asansör bulunmaktadır.
              Öğrencilerin rahat kullanımı açısından her yarım kat merdiven
              çıkışında yeni bir kata ulaşılmaktadır.
              <br />
              <br />
              Yangın algılama sistemi bulunan binada tüm iç kapılar, Yangın
              Yönetmeliği’ne uygun olarak koridor yönüne açılacak şekilde ve
              öğrencilere tehlike yaratmayacak şekilde niş içine alınmıştır.
              <br />
              <br />
              Binamızda ses yayın sistemi, data sistemleri, telefon ve network
              sistemleri bulunmakta olup modern bir eğitim sistemi için tüm
              teknolojik altyapı düşünülerek uygulanmıştır.
            </p>
          </div>
        </div>
        <div className="subPage_okulumuz-infoCard">
          <img
            alt="Sınıflarımız"
            src={require("../../../../assets/compressed/TKM_4336.webp")}
          />
          <div className="subPage_okulumuz-infoCard_textWrap">
            <h3>Sınıflarımız</h3>
            <p>
              Kreşimizde her yaş grubu için özelleşmiş 23 derslik mevcuttur.
              Ayrıca dans/drama/müzik atölyesi, spor odası ve alanları, STEM
              etkinlik alanı, konferans salonu gibi etkinlik mekânları ile uyku
              odaları, revir gibi bakım odaları vardır.
              <br />
              <br />
              Bina içinde çocukların oyun alanı olarak kullanacakları geniş bir
              “iç avlu” mevcut olup bu avlunun üzeri ışık kırıcılı, ısı
              izolasyonlu ve kırılmaz özelliklere sahip cam ile kapatılarak bina
              içinde doğal bir aydınlatma imkânı sağlanmıştır.
            </p>
          </div>
        </div>
        <div className="subPage_okulumuz-infoCard">
          <img
            alt="Taze Nefesler Açık Zihinler"
            src={require("../../../../assets/compressed/TKM_7367.webp")}
          />
          <div className="subPage_okulumuz-infoCard_textWrap">
            <h3>Taze Nefesler Açık Zihinler</h3>
            <p>
              Öğrencilere ait bölümlerde sağlık ve hijyen şartları ön planda
              düşünülerek havalandırma sistemi yapılmıştır. Doğal ortamdan %100
              alınan temiz hava, filtrelerden geçirilip ısıtıp-soğutularak mekân
              içlerinde dolaşmakta ve geri dönüş havalandırma vasıtasıyla
              toplanan kirlenmiş hava direkt atmosfere verilmektedir.
              <br />
              <br />
              Ayrıca tüm döşeme kaplamalarında; PVC esaslı, anti bakteriyel,
              ekolojik ve geri dönüşümlü malzemeler kullanılmıştır. Tüm
              cephelerde su ve ısı yalıtımı yapılmıştır.
            </p>
          </div>
        </div>
        <div className="subPage_okulumuz-infoCard">
          <img
            alt="Bahçemiz"
            src={require("../../../../assets/compressed/TKM_3909.webp")}
          />
          <div className="subPage_okulumuz-infoCard_textWrap">
            <h3>Bahçemiz</h3>
            <p>
              Çocukların rahatça oynayacağı 3000 m2’lik çim bahçe, her geçen gün
              artan sayıda hayvanları ile Kampüs Çiftliği ve yazın hizmete giren
              yüzme havuzu sayesinde bahar ve yaz mevsimi çocuklarımız için tam
              bir festival havasında geçmekte doğadan kopmadan eğitimlerine
              devam edebilmektedirler.
              <br />
              <br />
              Servis araçlarının çocukları güvenli bir şekilde indirip
              bindirebilmesi için bahçemizde güvenlikli ayrı bir alan
              oluşturulmuştur.
            </p>
          </div>
        </div>
      </div>
    );
  },
];

export default page;
