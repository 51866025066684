import React from "react";
import { Helmet } from "react-helmet";

const page = [
  { pathName: "beslenme", pageName: "Beslenme" },
  function () {
    return (
      <div className="subPages_hizmetlerimiz-defaultCard">
        <Helmet>
        <title>Kampüs Kreş - Beslenme</title>
        <meta name="title" content="Kampüs Kreş - Beslenme" />
        <meta property="og:title" content="Kampüs Kreş - Beslenme" />
        <meta name="twitter:title" content="Kampüs Kreş - Beslenme" />

        <meta
      name="keywords"
      content="kreş yemek listesi, yemek listesi, kampüs kreş yemek listesi"
    />
      </Helmet>
        <div className="subPages_hizmetlerimiz-defaultCard_firstColumn">
          <div className="iframe">
            <a href="/assets/yemek_listesi.pdf" className="iframe_placeholder">
              Yemek Listesi
            </a>
            <div>
              <iframe
                title="Kampüs Kreş Yemek Listesi"
                scrolling="no"
                src={`https://docs.google.com/gview?url=${process.env.REACT_APP_HOST}/assets/yemek_listesi.pdf&embedded=true`}
              />
            </div>
          </div>
          <p className="iframe_warning">
            yemek listemizi yüksek çözünürlükte görüntülemek için üstüne
            tıklayınız
          </p>
        </div>
        <div className="subPages_hizmetlerimiz-defaultCard_secondColumn">
          <p>
            Yemeklerimiz, profesyonel bir ekip tarafından mutfağımızda
            pişirilmektedir. Ürünlerimiz günlük olarak tüketilmektedir.
            Çocuklarımıza kahvaltı, öğle yemeği, ikindi kahvaltısı olmak üzere
            üç öğün yemek servisi yapılmaktadır. Hazır meyve suyu ve hazır gıda
            verilmemektedir. Buna ek olarak bebeklerimize özel okulumuzda
            hazırlanan meyve püresi, yoğurt gibi ek besinler verilmektedir.
            Yemeklerimiz, porselen tabaklar ve ev sıcaklığındaki sunumlarla
            çocuklara servis edilmektedir.
          </p>
        </div>
      </div>
    );
  },
];

export default page;
