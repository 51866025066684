import React from "react";
import { Field } from "formik";

import "./styles.form.textInput.css";

import MarkResolver from "../MarkResolver.json";

export function TextInput(props) {
  const { children, required, type, ...propItems } = props;

  return (
    <div className="formInputWrap">
      <label htmlFor={propItems.id}>
        {MarkResolver.includes(children.charAt(children.length - 1))
          ? children
          : children + ":"}
        {required && <span>&nbsp;*</span>}
      </label>
      <div className="formTextInputWrap">
        <Field
          {...propItems}
          type={props.type || "text"}
          className="formTextInput"
          required={required ? true : false}
        />
      </div>
    </div>
  );
}
