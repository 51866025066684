import React from "react";
import { Helmet } from "react-helmet";
import { Link, Route, Navigate } from "react-router-dom";
import { DropdownFilledIcon } from "../../../assets/icons";
import { importAll } from "../../../tools/importAll";

import "../universal.sub.style.css";
import "./subPage.style.css";

const subPages = importAll(
  require.context("./subPages", true, /\.subPage.(js|jsx)$/)
);

const pathName = "galeri";
const pageName = "Galeri";

function ParentPageWrap({ children }) {
  const [mobileMenuState, setMobileMenuState] = React.useState(false);

  React.useEffect(() => {
    const navigationContainer = document.querySelector(".mobilePageNavigation");
    const navigationButton = document.querySelector(
      ".mobilePageNavigationToggleButton"
    );

    if (!mobileMenuState) {
      navigationContainer.style.top = `${
        navigationButton.offsetHeight + 80 - navigationContainer.offsetHeight
      }px`;
    } else {
      navigationContainer.style.top = `80px`;
    }
  }, [mobileMenuState]);

  return (
    <div className="defaultPageWrap">
      <Helmet>
        <title>Kampüs Kreş - Galeri</title>
        <meta name="title" content="Kampüs Kreş - Galeri" />
        <meta property="og:title" content="Kampüs Kreş - Galeri" />
        <meta name="twitter:title" content="Kampüs Kreş - Galeri" />
      </Helmet>
      <div className="pageNavigationWrap green">
        {Object.keys(subPages).map((key, i) => {
          let page = subPages[key].default;
          if (
            page[0].defaultView &&
            window.location.pathname.length < 1 + pathName.length + 2
          ) {
            window.location.href = `/${pathName}/${page[0].pathName}`;
          }
          return (
            <Link
              to={`/${pathName}/${page[0].pathName}`}
              className={`pageNavigationItem${
                window.location.pathname === `/${pathName}/${page[0].pathName}`
                  ? " active"
                  : ""
              }`}
              key={i}
            >
              {page[0].pageName}
            </Link>
          );
        })}
      </div>
      <div className="mobilePageNavigation green">
        <div className="mobilePageNavigationItemsWrap">
          {Object.keys(subPages).map((key, i) => {
            let page = subPages[key].default;
            if (
              page[0].defaultView &&
              window.location.pathname.length < 1 + pathName.length + 2
            ) {
              window.location.href = `/${pathName}/${page[0].pathName}`;
            }
            return (
              <Link
                to={`/${pathName}/${page[0].pathName}`}
                onClick={() => setMobileMenuState((oldState) => !oldState)}
                className={`mobilePageNavigationItem${
                  window.location.pathname ===
                  `/${pathName}/${page[0].pathName}`
                    ? " active"
                    : ""
                }`}
                key={i}
              >
                {page[0].pageName}
              </Link>
            );
          })}
        </div>
        <button
          onClick={() => setMobileMenuState((oldState) => !oldState)}
          className={`mobilePageNavigationToggleButton${
            mobileMenuState ? " active" : ""
          }`}
        >
          <DropdownFilledIcon />
          Alt Başlıkları {mobileMenuState ? "Gizlemek" : "Görüntülemek"} İçin
          Tıklayın <DropdownFilledIcon />
        </button>
      </div>
      {children}
    </div>
  );
}

const page = [
  { pathName, pageName },
  ParentPageWrap,
  function () {
    return [
      <Route
        key={100}
        path={`/${pathName}/anaokulu`}
        element={<Navigate to={`/${pathName}/okulda-yasam`} />}
      />,
      ...Object.keys(subPages).map((key, i) => {
        let page = subPages[key].default;
        let Element = page[1];
        return (
          <React.Fragment>
            <Route
              key={i}
              path={`/${pathName}/${page[0].pathName}`}
              element={
                <ParentPageWrap>
                  <Element />
                </ParentPageWrap>
              }
            />
            <Route
              key={i}
              path={`/${pathName}/${page[0].pathName}/*`}
              element={
                <ParentPageWrap>
                  <Element />
                </ParentPageWrap>
              }
            />
          </React.Fragment>
        );
      }),
    ];
  },
];

export default page;
