import React from "react";
import { Field } from "formik";

import "./styles.form.radioInput.css";

import MarkResolver from "../MarkResolver.json";

export function RadioInput(props) {
  const { children, radioButtons, name, required, ...propItems } = props;

  return (
    <div className="formInputWrap">
      <label htmlFor={propItems.id}>
        {MarkResolver.includes(children.charAt(children.length - 1))
          ? children
          : children + ":"}
        {required && <span>&nbsp;*</span>}
      </label>
      <Field name={name} {...propItems}>
        {({ field }) => {
          return radioButtons.map((label, i) => {
            i++;
            const customId = `${name}_${i}`;

            return (
              <React.Fragment key={i}>
                <div className="formRadioInput">
                  <input
                    type="radio"
                    id={customId}
                    {...field}
                    value={i.toString()}
                    checked={field.value === i.toString()}
                    required={required ? true : false}
                  />
                  <label htmlFor={customId}>{label}</label>
                </div>
              </React.Fragment>
            );
          });
        }}
      </Field>
    </div>
  );
}
