import { Helmet } from "react-helmet";
import "./styles.kvkk.css";

export function KVKK() {
  const pdfIcon = require("../../assets/service-icons/pdf-icon.png");

  const items = [
    {
      name: "Çerez Politikası",
      href: "/kvkk-docs/cerez-politikasi.pdf",
    },
    {
      name: "Gizlilik Politikası",
      href: "/kvkk-docs/gizlilik-politikasi.pdf",
    },
    {
      name: "Kamera Aydınlatma Metni",
      href: "/kvkk-docs/kamera-aydinlatma-metni.pdf",
    },
    {
      name: "KVKK Aydınlatma Metni",
      href: "/kvkk-docs/kvkk-aydinlatma-metni.pdf",
    },
    {
      name: "KVKK Veri Sorumlusu",
      href: "/kvkk-docs/kvkk-veri-sorumlusu.pdf",
    },
    {
      name: "Veri Sahibi Talep Formu",
      href: "/kvkk-docs/veri-sahibi-talep-formu.pdf",
    },
    {
      name: "Ziyaretçi Aydınlatma Metni",
      href: "/kvkk-docs/ziyaretci-aydinlatma-metni.pdf",
    },
  ];

  return (
    <div className="wrap">
      <Helmet>
        <title>Kampüs Kreş - KVKK</title>
        <meta name="title" content="Kampüs Kreş - KVKK" />
        <meta property="og:title" content="Kampüs Kreş - KVKK" />
        <meta name="twitter:title" content="Kampüs Kreş - KVKK" />
      </Helmet>
      {items.map((v) => (
        <a className="box" href={v.href}>
          <img src={pdfIcon} alt="PDF Icon" />
          <h1>{v.name}</h1>
        </a>
      ))}
    </div>
  );
}
