import React from "react";
import { Helmet } from "react-helmet";

const page = [
  { pathName: "rehberlik", pageName: "Rehberlik", defaultView: true },
  function () {
    return (
      <div className="subPages_hizmetlerimiz-rehberlik_wrap">
        <Helmet>
        <title>Kampüs Kreş - Rehberlik</title>
        <meta name="title" content="Kampüs Kreş - Rehberlik" />
        <meta property="og:title" content="Kampüs Kreş - Rehberlik" />
        <meta name="twitter:title" content="Kampüs Kreş - Rehberlik" />
      </Helmet>
        <div className="subPages_hizmetlerimiz-rehberlik_card">
          <h1>Oryantasyon Çalışmaları</h1>
          <p>
            Çocukların evden ve ailelerinden ilk ayrılık deneyimlerini en
            sağlıklı şekilde yaşamaları için oryantasyon çalışmaları
            yapılmaktadır. Bu süreçte çocuğun okul ortamına ve öğretmenine yavaş
            yavaş alışması, güven duyması ve ihtiyaçlarını annesi dışında bir
            başka yetişkinin de karşılayabileceğini deneyimlemesi için gerekli
            çalışmalar yapılmaktadır. Bu çalışmalar sırasındaki gözlemler
            aileler ile paylaşılmakta ve çocuğun ev yaşantısı, doğum bilgileri
            ayrıntılı bir şekilde aileler tarafından edinilmektedir. Bu süreçte
            problem yaşayan öğrencilere ve velilere gerekli destek ve
            yönlendirmeler yapılmaktadır.
          </p>
        </div>
        <div className="subPages_hizmetlerimiz-rehberlik_card">
          <h1>Ölçme ve Değerlendirme</h1>
          <p>
            Okulumuza kaydı alınan her öğrenciye Denver ll Gelişimsel Tarama
            Testi uygulanır. Ölçme çalışmaları sonrası her bir çocuğun
            gereksinim duyduğu alanlarda rehberlik servisi tarafından hazırlanan
            programla “Gelişim Destekleme Çalışmaları” yapılmaktadır. Bu
            değerlendirmeler sonucunda çocukların gelişimlerinde en üst noktaya
            ulaşabilmeleri için bireysel çalışmalar da yapılmaktadır. Sınıf
            içinde farklı durum ve davranışlarda bulunan öğrencilere gerekli
            testler uygulanır, test sonuçları ve aileden alınan bilgiler
            doğrultusunda durumun çözümüne yönelik öneri ve yönlendirmelerde
            bulunulur. Hazırlık grubu öğrencilerimiz için İlkokula Hazır Oluş
            Ölçeği uygulanır ve sonuçları veliler ile paylaşılır. İlkokula
            başlama ve okul tercihi konusunda velilerimize danışmanlık yapılır.
          </p>
        </div>
        <div className="subPages_hizmetlerimiz-rehberlik_card">
          <h1>Gözlem ve Raporlama</h1>
          <p>
            Tüm çocuklarımız psikoloğumuz tarafından sınıf ortamında
            gözlemlenir. Oyun ortamında arkadaş ilişkileri, aldığı roller,
            benimsediği davranış kalıpları, kurallara uyma düzeyi, sosyal
            becerileri, iletişim düzeyleri, dikkat süresi, sorumluluk alma,
            paylaşımcılık gibi birçok özelliği hakkında bilgi edinilir. Bu
            bilgiler ışığında gerekli durumlarda çocuklarla bireysel çalışmalar
            yapılmaktadır.
          </p>
        </div>
        <div className="subPages_hizmetlerimiz-rehberlik_card">
          <h1>Veli Görüşmeleri</h1>
          <p>
            Öğrencilerin gelişimlerini takip edebilmeleri adına anne ve
            babalarla randevulu bireysel görüşmeler yapılmakta, yapılan tarama
            ve ölçme değerlendirme çalışmalarından elde edilen bulgular ve
            öğretmenlerden alınan bilgiler aileler ile paylaşılmaktadır.
            Öğrencilerdeki gelişimi kalıcı kılmak için önerilerde
            bulunulmaktadır.
          </p>
        </div>
        <div className="subPages_hizmetlerimiz-rehberlik_card">
          <h1> Seminer/Eğitimler</h1>
          <p>
            İhtiyaç ve talepler doğrultusunda belirlenen psikolojik konular
            hakkında öğretmenlerimize ve/veya velilerimize seminerler
            düzenlenir.
          </p>
        </div>
      </div>
    );
  },
];

export default page;
