import React, { useEffect } from "react";

import { useQuery, useMutation } from "@apollo/client";
// import {} from "../../../graphql/mutations";
import { getFormDetails } from "../../../graphql/queries";
import { updateFieldsMutation } from "../../../graphql/mutations";

import "./styles.editform.css";
import {
  LockIcon,
  CalendarIcon,
  CheckBoxIcon,
  PenIcon,
  TrashIcon,
  TextIcon,
  CheckMarkIcon,
  AsteriskIcon,
} from "../../../assets/icons";
import { Helmet } from "react-helmet";

export function EditFormPage() {
  const { loading, data } = useQuery(getFormDetails);
  const [saveFields] = useMutation(updateFieldsMutation);

  const [editing, setEditing] = React.useState({});
  const [fields, setFields] = React.useState([]);

  const saveFieldsParent = React.useCallback(
    (values) => {
      saveFields({
        variables: {
          fields: values.map((value) => {
            return {
              _id: value._id,
              type:
                value.type === "text" ? 1 : value.type === "checkbox" ? 2 : 3,
              fieldName: value.name,
              required: value.required,
            };
          }),
          session: window.localStorage.getItem("session"),
        },
      });
    },
    [saveFields]
  );

  useEffect(() => {
    if (fields) {
      saveFieldsParent(fields);
    }
  }, [fields, saveFieldsParent]);

  useEffect(() => {
    if (!loading) {
      setFields((oldFields) => {
        return [
          ...oldFields,
          ...data.getFields.booleanFields.map((value) => {
            return {
              _id: value._id,
              name: value.fieldName,
              type: "checkbox",
              required: value.required,
            };
          }),
          ...data.getFields.textFields.map((value) => {
            return {
              _id: value._id,
              name: value.fieldName,
              type: "text",
              required: value.required,
            };
          }),
          ...data.getFields.dateFields.map((value) => {
            return {
              _id: value._id,
              name: value.fieldName,
              type: "date",
              required: value.required,
            };
          }),
        ];
      });
    }
  }, [loading, data]);

  if (loading) return <p>loading fields...</p>;

  return (
    <div className="formWrapper">
      <Helmet>
        <title>Kampüs Kreş - Admin</title>
        <meta name="title" content="Kampüs Kreş - Admin" />
        <meta property="og:title" content="Kampüs Kreş - Admin" />
        <meta name="twitter:title" content="Kampüs Kreş - Admin" />
      </Helmet>
      <div className="lockedFields">
        <div className="lockedField">
          <p>Öğrencinin Adı Soyadı</p>
          <LockIcon />
        </div>
        <div className="lockedField">
          <p>Cinsiyet</p>
          <LockIcon />
        </div>
        <div className="lockedField">
          <p>Doğum Tarihi</p>
          <LockIcon />
        </div>
        <div className="lockedField">
          <p>Sınıf Kategorisi</p>
          <LockIcon />
        </div>
        <div className="lockedField">
          <p>Veli Yakınlık Derecesi</p>
          <LockIcon />
        </div>
        <div className="lockedField">
          <p>Veli Ad Soyad</p>
          <LockIcon />
        </div>
        <div className="lockedField">
          <p>Telefon Numarası</p>
          <LockIcon />
        </div>
        <div className="lockedField">
          <p>Email</p>
          <LockIcon />
        </div>
      </div>
      <div className="fieldsWrap">
        <div className="fieldsInnerWrap">
          {fields.map((field, i) => (
            <div key={field._id} className="fieldWrap">
              <input
                onChange={(e) => {
                  setEditing({
                    _id: field._id,
                    input: e.target.value || field.name,
                  });
                }}
                disabled={editing._id === field._id ? false : true}
                defaultValue={field.name}
              />
              <div className="fieldActionButtonsWrap">
                <div>
                  {field.type === "text" ? (
                    <TextIcon />
                  ) : field.type === "date" ? (
                    <CalendarIcon />
                  ) : field.type === "checkbox" ? (
                    <CheckBoxIcon className="checkbox" />
                  ) : null}
                </div>
                {editing._id === field._id ? (
                  <React.Fragment>
                    <button
                      onClick={() => {
                        setFields((oldFields) => {
                          return oldFields.map((object) => {
                            if (object._id === field._id) {
                              return {
                                ...object,
                                required: !object.required,
                              };
                            }
                            return object;
                          });
                        });
                      }}
                      className={`asterisk ${field.required ? "green" : "red"}`}
                    >
                      <AsteriskIcon />
                    </button>
                    <button
                      onClick={() => {
                        setFields((oldFields) => {
                          return oldFields.map((object) => {
                            if (object._id === field._id) {
                              return {
                                ...object,
                                name: editing.input || field.name,
                              };
                            }
                            return object;
                          });
                        });
                        setEditing({});
                      }}
                    >
                      <CheckMarkIcon />
                    </button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <button
                      onClick={() => {
                        setFields((oldFields) => {
                          return oldFields
                            .filter((value) => {
                              return value._id !== field._id;
                            })
                            .map((value) => {
                              return value;
                            });
                        });
                      }}
                    >
                      <TrashIcon />
                    </button>
                    <button onClick={() => setEditing({ _id: field._id })}>
                      <PenIcon />
                    </button>
                  </React.Fragment>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="actionButtons">
          <button
            onClick={() =>
              setFields((oldFields) => {
                return [
                  ...oldFields,
                  {
                    _id:
                      fields.length > 0 ? fields[fields.length - 1]._id + 1 : 1,
                    name: "Yeni Yazı Katmanı",
                    type: "text",
                    required: false,
                  },
                ];
              })
            }
          >
            Yazı Katmanı Oluştur
          </button>
          <button
            onClick={() =>
              setFields((oldFields) => {
                return [
                  ...oldFields,
                  {
                    _id:
                      fields.length > 0 ? fields[fields.length - 1]._id + 1 : 1,
                    name: "Yeni Tarih Katmanı",
                    type: "date",
                    required: false,
                  },
                ];
              })
            }
          >
            Tarih Katmanı Oluştur
          </button>
          <button
            onClick={() =>
              setFields((oldFields) => {
                return [
                  ...oldFields,
                  {
                    _id:
                      fields.length > 0 ? fields[fields.length - 1]._id + 1 : 1,
                    name: "Yeni Seçenek Katmanı",
                    type: "checkbox",
                    required: false,
                  },
                ];
              })
            }
          >
            Seçenek Katmanı Oluştur
          </button>
        </div>
      </div>
    </div>
  );
}
