export function calcAge(birth) {
  const birthYear = birth.split("-")[0];
  const birthMonth = birth.split("-")[1];
  const birthDay = birth.split("-")[2];
  const now = new Date().toLocaleDateString();
  const currentDay =
    now.split(".").length !== 1 ? now.split(".")[0] : now.split("/")[0];
  const currentMonth =
    now.split(".").length !== 1 ? now.split(".")[1] : now.split("/")[1];
  const currentYear =
    now.split(".").length !== 1 ? now.split(".")[2] : now.split("/")[2];

  let age = currentYear - parseInt(birthYear);
  let monthlyAge = currentMonth - parseInt(birthMonth);
  let dayAge = currentDay - parseInt(birthDay);

  if (age < 1) {
    if (monthlyAge < 1)
      return {
        range: dayAge,
        format: `${dayAge} Günlük`,
        dateType: "day",
      };
    return {
      range: monthlyAge,
      format: `${monthlyAge} Aylık`,
      dateType: "month",
    };
  }
  return {
    range: age,
    format: `${age} Yaş`,
    dateType: "year",
  };
}
