import React from "react";

import { useQuery, useMutation } from "@apollo/client";
import { getFormDetails } from "../../graphql/queries";
import { createRegistryFormMutation } from "../../graphql/mutations";
import { Formik } from "formik";
import Markdown from "react-markdown";

import * as Yup from "yup";

import {
  TextInput,
  RadioInput,
  DateInput,
  CheckBoxInput,
} from "../../components/Form/index";

import "../../styles.formPage.css";
import { CloseIcon, SpinnerIcon } from "../../assets/icons";

import quotaResolver from "../admin/Forms/resolvers/quotaResolver.json";
import { sortArrayUsingID } from "../../tools/sortArray";
import { Helmet } from "react-helmet";

let years = ["2023 - 2024", "2022", "2021", "2020", "2019"];
let current = "2024 - 2025";

let closed = false;
let forward =
  "https://kampuskres.com.tr/okulumuz/duyurular?id=63c6e5a9debe037231fc6167";

function QuotaAnnouncerModal({ quotas, on, setOn }) {
  return (
    <React.Fragment>
      <div className={`quotaModal${on ? " active" : " inactive"}`}>
        <div className="modalHeaderWrap">
          <h1 className="modalTitle">{current} Kontenjan Durumu</h1>
          <button onClick={() => setOn(false)} className="modalCloseButton">
            <CloseIcon />
          </button>
        </div>
        {sortArrayUsingID([...quotas]).map((quota, i) => (
          <div key={i} className="modalQuotaWrap">
            {`${quotaResolver[quota._id]} | ${years[quota._id]}`}
            <div className={quota.openQuota ? "openButton" : "closedButton"}>
              {quota.openQuota ? "Kontenjan Var" : "Yedek Liste Alımı"}
            </div>
          </div>
        ))}
      </div>
      <div
        onClick={() => setOn(false)}
        className={`modalBg${on ? " active" : " inactive"}`}
      ></div>
    </React.Fragment>
  );
}

export function RegistryFormComponent() {
  const { data, loading } = useQuery(getFormDetails);
  const [createRegistryForm] = useMutation(createRegistryFormMutation);
  const [smsOk, setSmsOk] = React.useState(false);
  const [telOk, setTelOk] = React.useState(false);
  const [mailOk, setMailOk] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [on, setOn] = React.useState(false);

  if (closed) window.location.href = forward;

  // eslint-disable-next-line no-extend-native
  String.prototype.replaceAtCallback = function (index, char) {
    let a = this.split("");
    a[index] = char(a[index]);
    return a.join("");
  };
  // eslint-disable-next-line no-extend-native
  String.prototype.replaceAt = function (index, char) {
    let a = this.split("");
    a[index] = char;
    return a.join("");
  };

  function handlePhoneNumberChange(event, func) {
    let value = event.target.value;

    // remove characters that arent digits and parantheses
    value = value.replace(/((?!\(|\)|\d).)/g, "");

    let valueCharArray = value.split("");

    // remove unwanted parantheses
    for (let i = 0; i < valueCharArray.length; i++) {
      if (valueCharArray[i] === "(" && i !== 1) {
        value = value.replaceAt(i, "");
        // update char array
        valueCharArray = value.split("");
      }
      if (valueCharArray[1] === "(" && valueCharArray[i] === ")" && i !== 5) {
        value = value.replaceAt(i, "");
        // update char array
        valueCharArray = value.split("");
      }
    }

    // remove both parantheses if one of them is removed
    if (!valueCharArray.includes(")") && valueCharArray.includes("(")) {
      event.target.value = value;
      func(event);
      return setPhoneNumber(value.replace("(", ""));
    }
    if (valueCharArray.includes(")") && !valueCharArray.includes("(")) {
      event.target.value = value;
      func(event);
      return setPhoneNumber(value.replace(")", ""));
    }

    // add parantheses
    if (!valueCharArray.includes(")")) {
      if (value.length >= 4 && !valueCharArray.includes("(")) {
        let valueWithGuides = value.replaceAtCallback(0, (matched) => {
          return `${matched}{$}`;
        });
        let substring = valueWithGuides.substring(1, 7);
        let prettified = valueWithGuides.replace(substring, (matched) => {
          return `(${matched.replace("{$}", "")})`;
        });

        value = prettified;
        valueCharArray = value.split("");
        event.target.value = value;
        func(event);
        return setPhoneNumber(prettified);
      }
    }

    if (valueCharArray.includes("(") && valueCharArray.includes(")")) {
      // first space
      if (valueCharArray[6] !== " " && valueCharArray[6]) {
        value = value.replaceAtCallback(6, (matched) => {
          return ` ${matched}`;
        });
        valueCharArray = value.split("");
      }

      // second space
      if (valueCharArray[10] !== " " && valueCharArray[10]) {
        value = value.replaceAtCallback(10, (matched) => {
          return ` ${matched}`;
        });
        valueCharArray = value.split("");
      }

      // third space
      if (valueCharArray[13] !== " " && valueCharArray[13]) {
        value = value.replaceAtCallback(13, (matched) => {
          return ` ${matched}`;
        });
        valueCharArray = value.split("");
      }
    }

    // change the last digit (15th) of the number with the last input
    if (valueCharArray.length > 16) value = value.replaceAt(15, "");

    event.target.value = value;
    func(event);
    return setPhoneNumber(value);
  }

  if (loading)
    return (
      <div
        style={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
          top: "35%",
          left: "50%",
        }}
        className="loading"
      >
        <Helmet>
          <title>Kampüs Kreş - Öğrenci Başvuru Formu</title>
          <meta name="title" content="Kampüs Kreş - Öğrenci Başvuru Formu" />
          <meta
            property="og:title"
            content="Kampüs Kreş - Öğrenci Başvuru Formu"
          />
          <meta
            name="twitter:title"
            content="Kampüs Kreş - Öğrenci Başvuru Formu"
          />

          <meta
            name="keywords"
            content="kreş online başvuru, online başvuru, kampüs kreş Öğrenci Başvuru, kampüs kreş Online Başvuru"
          />
        </Helmet>
        <SpinnerIcon />
        <p>Sayfa Yükleniyor</p>
      </div>
    );

  let now = new Date();
  let nowISO = now.toISOString();

  let initialValues = {
    birth: "",
    educationPeriod: "",
    smsOk: "",
    telOk: "",
    mailOk: "",
    email: "",
    foundAt: "",
  };

  data.getFields.dateFields.forEach((field, i) => {
    Object.assign(initialValues, { [`custom_${field._id}`]: "" });
  });

  const create = async (input) => {
    let textFields = [];
    let booleanFields = [];
    let dateFields = [];

    Object.keys(input).forEach((value) => {
      if (value.includes("text_")) {
        let index = data.getFields.textFields.findIndex((field) => {
          if (field._id.toString() === value.split("_")[1]) {
            return true;
          }
          return false;
        });
        let fieldName = data.getFields.textFields[index].fieldName;
        textFields.push({
          fieldName,
          input: input[value],
        });
      }

      if (value.includes("checkbox_")) {
        let index = data.getFields.booleanFields.findIndex((field) => {
          if (field._id.toString() === value.split("_")[1]) {
            return true;
          }
          return false;
        });
        let fieldName = data.getFields.booleanFields[index].fieldName;
        booleanFields.push({
          fieldName,
          input: input[value],
        });
      }

      if (value.includes("date_")) {
        let index = data.getFields.dateFields.findIndex((field) => {
          if (field._id.toString() === value.split("_")[1]) {
            return true;
          }
          return false;
        });
        let fieldName = data.getFields.dateFields[index].fieldName;
        dateFields.push({
          fieldName,
          input: input[value],
        });
      }
    });

    try {
      await createRegistryForm({
        variables: {
          name: input.fullName,
          gender: parseInt(input.gender),
          birth: `${input.birth}$${input.educationPeriod}$${telOk ? 1 : 0}.${
            mailOk ? 1 : 0
          }.${smsOk ? 1 : 0}$${input.foundAt}`,
          classType: parseInt(input.classType),
          applicator: {
            name: input.applicator_fullName,
            degreeOfCloseness: input.degreeOfCloseness,
            inOSB: parseInt(input.inOSB) === 1 ? true : false,
            phoneNumber: input.tel,
            email: input.email,
          },
          textFields,
          booleanFields,
          dateFields,
        },
        ignoreResults: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const formValidator = Yup.object().shape({
    smsOk: Yup.boolean(),
    telOk: Yup.boolean(),
    mailOk: Yup.boolean().test(
      "oneChecked",
      "İletişim yollarından en az birini seçmeniz gerekmektedir. (E-Mail ve Telefon iletişim seçeneklerinden en az biri zorunludur)",
      () => {
        if (!telOk && !mailOk) return false;
        return true;
      }
    ),
  });

  return (
    <div className="formOuterWrap">
      {/* INFO "Füsun hanimin istegi uzerine gecici olarak kaldirildi" <QuotaAnnouncerModal on={on} setOn={setOn} quotas={data.getQuotas} />
      <button
        className="quotaOpenButton"
        onClick={() => setOn((oldState) => !oldState)}
      >
        Kontenjan Durumunu Görüntüle
      </button> */}
      <div className="formWrap">
        <div className="formTitle">
          <h1
            style={{
              color: "var(--blackText)",
              fontSize: "17.5px",
              fontWeight: "550",
              alignSelf: "center",
            }}
          >
            {current} Öğrenci Başvuru Formu / Student Application Form
          </h1>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={formValidator}
          validateOnChange={true}
          validateOnMount={true}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            create(values);
            setTimeout(() => {
              actions.setSubmitting(false);
              setTimeout(() => {
                window.location.href = process.env.REACT_APP_HOST;
              }, 3000);
            }, 1500);
          }}
        >
          {(props) =>
            !props.isSubmitting ? (
              props.submitCount > 0 ? (
                <p style={{ fontSize: "13pt" }}>
                  Formunuz Başarıyla Gönderildi
                </p>
              ) : (
                <form onSubmit={props.handleSubmit}>
                  <TextInput
                    id="fullName"
                    name="fullName"
                    placeholder="Ad Soyad / Name Surname"
                    onChange={props.handleChange}
                    required
                  >
                    Öğrencinin Adı Soyadı / Student’s Name-Surname:
                  </TextInput>
                  <RadioInput
                    id="gender"
                    name="gender"
                    radioButtons={["Erkek / Male", "Kız / Female"]}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    required
                  >
                    Öğrencinin Cinsiyeti / Student’s Gender
                  </RadioInput>
                  <DateInput
                    id="birth"
                    name="birth"
                    min={`${parseInt(now.getFullYear()) - 5}-01-01`}
                    max={nowISO.substring(0, nowISO.search(/[A-Z]/))}
                    onChange={(e) => {
                      props.handleChange(e);
                    }}
                    onBlur={props.handleBlur}
                    required
                  >
                    Öğrencinin Doğum Tarihi / Student’s Date of Birth
                  </DateInput>
                  <RadioInput
                    id="educationPeriod"
                    name="educationPeriod"
                    radioButtons={sortArrayUsingID([...data.getQuotas]).map(
                      (quota) => {
                        return `${quotaResolver[quota._id]} | ${
                          years[quota._id]
                        } doğumlular`;
                      }
                    )}
                    onChange={(e) => {
                      props.handleChange(e);
                    }}
                    onBlur={props.handleBlur}
                    required
                  >
                    Lütfen başvurmak istediğiniz yaş grubunu seçiniz. / Please
                    select the education period that you want to register.
                  </RadioInput>
                  <RadioInput
                    id="classType"
                    name="classType"
                    radioButtons={[
                      "Anadil Sınıfları ( İki yaştan itibaren öğrenci kabul edilmektedir. ) / Non-Bilingual Classrooms ( Acceptance by age 2 )",
                      "Çift Dilli Eğitim Sınıfları ( İki yaştan itibaren öğrenci kabul edilmektedir. ) / Bilingual Education Classrooms ( Acceptance by age 2 )",
                      "Kreş Bölümü ( 6 aylıktan 2 yaşa kadar çocuklar kabul edilmektedir. ) / Nursery ( Acceptance by 6 months to age 2 )",
                    ]}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    required
                  >
                    Sınıf Kategorisi (Aday öğrencimiz için tercih ettiğiniz
                    sınıf kategorisini belirtiniz) / Classroom Category (Specify
                    your classroom preference for our candidate student)
                  </RadioInput>
                  <TextInput
                    id="applicator_fullName"
                    name="applicator_fullName"
                    placeholder="Ad Soyad / Name Surname"
                    onChange={props.handleChange}
                    required
                  >
                    Formu Dolduran Kişinin Adı-Soyadı / Name-Surname who fills
                    the form
                  </TextInput>
                  <TextInput
                    id="applicator_degreeOfCloseness"
                    name="degreeOfCloseness"
                    placeholder="Anne, Baba vs. / Mom, Dad etc."
                    onChange={props.handleChange}
                    required
                  >
                    Formu Dolduran Kişinin Aday Öğrenciye Yakınlık Derecesi /
                    What is the closeness of the person who fills the form to
                    the candidate student?
                  </TextInput>
                  <TextInput
                    id="phoneNumber"
                    name="tel"
                    type="tel"
                    placeholder="0(000) 000 00 00"
                    pattern="[0][(][0-9]{3}[)] [0-9]{3} [0-9]{2} [0-9]{2}"
                    onChange={(e) => {
                      handlePhoneNumberChange(e, (newE) => {
                        props.handleChange(newE);
                      });
                    }}
                    value={phoneNumber}
                    required
                  >
                    İletişim Numarası / Contact Number
                  </TextInput>
                  <TextInput
                    id="email"
                    name="email"
                    type="email"
                    placeholder="email@example.com"
                    onChange={props.handleChange}
                    required
                  >
                    E-mail Adresi / E-mail adress
                  </TextInput>
                  <RadioInput
                    name="inOSB"
                    radioButtons={[
                      "OSB Bölge İçi / OSB",
                      "OSB Bölge Dışı / Out of OSB",
                    ]}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    required
                  >
                    Çalıştığı Kurum / Currently Work (Okulumuz Dudullu Organize
                    Sanayi Bölgesi Başkanlığı tarafından kurulmuştur. Bu nedenle
                    Dudullu OSB firmalarından birinde çalışmanız durumunda
                    "Bölge İçi" şıkkını, eğer Dudullu OSB firmalarından birinde
                    çalışmıyorsanız "Bölge Dışı" şıkkını işaretleyiniz) / (Our
                    school was established by Dudullu Organization Industrial
                    Area Management. That’s why if you work at a Dudullu OSB
                    company choose OSB section, if you don’t work at a Dudullu
                    OSB company choose out of OSB)
                  </RadioInput>
                  <RadioInput
                    id="foundAt"
                    name="foundAt"
                    radioButtons={[
                      "Web Sitesi",
                      "İnternet",
                      "Instagram",
                      "OSB Kanalıyla",
                      "Billboard",
                      "Tavsiye",
                      "Broşür",
                    ]}
                    onChange={(e) => {
                      props.handleChange(e);
                    }}
                    onBlur={props.handleBlur}
                    required
                  >
                    Kurumumuz hakkındaki bilgiyi hangi kaynaktan aldınız?
                  </RadioInput>
                  {data.getFields.textFields.map((field, i) => (
                    <TextInput
                      key={i}
                      id={`custom_${field._id}`}
                      name={`text_${field._id}`}
                      required={field.required}
                      onChange={props.handleChange}
                      placeholder="Yanıtınız / Your Answer"
                    >
                      {field.fieldName}
                    </TextInput>
                  ))}
                  {data.getFields.dateFields.map((field, i) => (
                    <DateInput
                      key={i}
                      id={`custom_${field._id}`}
                      name={`date_${field._id}`}
                      required={field.required}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    >
                      {field.fieldName}
                    </DateInput>
                  ))}
                  {data.getFields.booleanFields.map((field, i) => (
                    <CheckBoxInput
                      key={i}
                      id={`custom_${field._id}`}
                      name={`checkbox_${field._id}`}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      required={field.required}
                      surround
                    >
                      <Markdown>{field.fieldName}</Markdown>
                    </CheckBoxInput>
                  ))}
                  <CheckBoxInput
                    id="ihaveread"
                    name="iHaveRead"
                    onChange={props.handleChange}
                    required
                  >
                    <a href="/documents/aydinlatma-metni" target="_blank">
                      Kayıt Formu Aydınlatma Metnini
                    </a>{" "}
                    okudum kabul ediyorum.
                  </CheckBoxInput>
                  <div>
                    <CheckBoxInput
                      id="smsOk"
                      name="smsOk"
                      onChange={(e) => {
                        setSmsOk(e.target.checked);
                        props.handleChange(e);
                      }}
                    >
                      Kampüs Kreş'ten SMS gönderilmesine açık rızam vardır.
                    </CheckBoxInput>
                    <CheckBoxInput
                      id="telOk"
                      name="telOk"
                      onChange={(e) => {
                        setTelOk(e.target.checked);
                        props.handleChange(e);
                      }}
                    >
                      Kampüs Kreş'ten çağrı yolu ile iletişime geçilmesine açık
                      rızam vardır.
                    </CheckBoxInput>
                    <CheckBoxInput
                      id="mailOk"
                      name="mailOk"
                      onChange={(e) => {
                        setMailOk(e.target.checked);
                        props.handleChange(e);
                      }}
                    >
                      Kampüs Kreş'ten e-posta gönderilmesine açık rızam vardır.
                    </CheckBoxInput>
                  </div>
                  <CheckBoxInput
                    id="iAgreeKVKK"
                    name="iAgreeKVKK"
                    onChange={props.handleChange}
                    required
                  >
                    Formda belirttiğim kendime ve çocuğuma işlenmesine,
                    korunmasına ve gerektiğinde aktarılmasına açıkça rıza
                    gösteriyorum. / I expressly consent to the processing,
                    protection and transfer of personal information belonging to
                    myself, my child and the 3rd person that I have stated in
                    the form.
                  </CheckBoxInput>
                  {props.errors.mailOk ? (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      {props.errors.mailOk}
                    </span>
                  ) : null}
                  <button
                    style={
                      props.errors.mailOk
                        ? { cursor: "not-allowed", opacity: ".5" }
                        : {}
                    }
                    disabled={props.errors.mailOk ? true : false}
                    className="formSubmitButton"
                    type="submit"
                  >
                    Gönder / Send
                  </button>
                </form>
              )
            ) : (
              <p style={{ fontSize: "13pt" }}>Formunuz Gönderiliyor...</p>
            )
          }
        </Formik>
      </div>
    </div>
  );
}
