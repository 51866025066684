import { useQuery } from "@apollo/client";
import React from "react";
import { InfoIcon, SpinnerIcon } from "../../../../../assets/icons";
import { NotFound } from "../../../../../components/NotFound";
import { getImages } from "../../../../../graphql/queries";
import { toUrlSafe } from "../../../../../tools/urlSafeFormat";

export function createGalleryLayout(pathName, pageName, type, defaultView) {
  return [
    { pathName, pageName, defaultView },
    function () {
      const { data, loading } = useQuery(getImages, { variables: { type } });

      const [tooltip, setTooltip] = React.useState("");

      if (loading)
        return (
          <div
            style={{
              position: "absolute",
              transform: "translate(-50%, -50%)",
              top: "50%",
              left: "50%",
            }}
            className="loading"
          >
            <SpinnerIcon />
            <p>Sayfa Yükleniyor</p>
          </div>
        );

      if (!loading) {
        if (data.getImages?.length === 0) {
          return <NotFound title="Gösterilecek Fotoğraf Bulunmamakta" />;
        }
      }

      return (
        <div className="subPages_galeri">
          {!loading &&
            data.getImages.map((image, i) => (
              <div>
                <div key={i}>
                  {image.displayName !== "none" && (
                    <i
                      onMouseEnter={() => setTooltip(i)}
                      onMouseLeave={() => setTooltip(null)}
                      onClick={() => {
                        if(!tooltip) {
                          setTooltip(i);
                        } else if(tooltip !== i) {
                          setTooltip(i);
                        } else {
                          setTooltip(null);
                        }
                      }}
                      className="infoButton"
                    >
                      <InfoIcon />
                      {tooltip === i && (
                        <div className="info">
                          <p>{image.displayName}</p>
                        </div>
                      )}
                    </i>
                  )}
                  <img
                    loading="lazy"
                    onClick={() => window.location.href = image.location}
                    alt={toUrlSafe(image.displayName !== "none" ? image.displayName : "")}
                    src={image.thumbnail_location || image.location}
                  />
                </div>
              </div>
            ))}
        </div>
      );
    },
  ];
}
