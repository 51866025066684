import React from "react";
import { Helmet } from "react-helmet";

const page = [
  { pathName: "calisma-saatleri", pageName: "Çalışma Saatleri" },
  function () {
    return (
      <div className="tableWithNoteWrap">
        <Helmet>
        <title>Kampüs Kreş - Çalışma Saatleri</title>
        <meta name="title" content="Kampüs Kreş - Çalışma Saatleri" />
        <meta property="og:title" content="Kampüs Kreş - Çalışma Saatleri" />
        <meta name="twitter:title" content="Kampüs Kreş - Çalışma Saatleri" />

        <meta
      name="keywords"
      content="kreş Çalışma Saatleri, Çalışma Saatleri, kampüs kreş Çalışma Saatleri"
    />
      </Helmet>
        <table className="table withBorders">
          <tr>
            <th>Program</th>
            <th>Eğitim Saatleri</th>
            <th>Yemek</th>
          </tr>
          <tr>
            <td>Tam Gün</td>
            <td>07:30 – 18:30</td>
            <td>Sabah Kahvaltısı Öğle Yemeği İkindi Kahvaltısı</td>
          </tr>
        </table>
        <ul>
          <li>Kreşimiz, hafta içi eğitim vermektedir.</li>
          <li>Oryantasyon sürecinde yarım gün seçeneğimiz mevcuttur.</li>
        </ul>
      </div>
    );
  },
];

export default page;
