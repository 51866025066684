import React from "react";
// import { DateFilledIcon } from "../../../assets/icons";

import "../styles.home.content.css";

export function ReviewCard({ avatar, name, children, date, fit }) {
  return (
    <div style={fit ? { height: "fit-content" } : {}} className="reviewCard">
      <div className="reviewCardInnerWrap">
        <div className="reviewAuthorDetails">
          <img src={avatar} alt="Erkek Avatar" />
          <div className="reviewsAuthorTextWrap">
            <h3>{name}</h3>
            <span>Kampüs Kreş velisi</span>
          </div>
        </div>
        <p>{children}</p>
      </div>
      {/* <div className="reviewDate">
        <DateFilledIcon />
        <p>{date}</p>
      </div> */}
    </div>
  );
}
