import React from "react";

import "./styles.notfound.css";

export function NotFound({ title }) {
  const notfoundimg = require("../../assets/compressed/498.webp");

  return (
    <div className="notFound">
      <img src={notfoundimg} alt="Büyüteç tutan kampüslüler" />
      <p>{title}</p>
    </div>
  );
}
