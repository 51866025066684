import React from "react";
import { Helmet } from "react-helmet";

export function AcikRiza() {
  document.addEventListener(
    "contextmenu",
    function (e) {
      e.preventDefault();
    },
    false
  );
  return (
    <article className="document">
      <Helmet>
        <title>Kampüs Kreş - Açık Rıza Metni</title>
        <meta name="title" content="Kampüs Kreş - Açık Rıza Metni" />
        <meta property="og:title" content="Kampüs Kreş - Açık Rıza Metni" />
        <meta name="twitter:title" content="Kampüs Kreş - Açık Rıza Metni" />
      </Helmet>
      <header>
        İSTANBUL DUDULLU ORGANİZE SANAYİ BÖLGESİ KAMPÜS KREŞ VE GÜNDÜZ BAKIMEVİ
        <br />
        6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU
        ÖĞRENCİ-VELİ-VASİ-TEMSİLCİ ÖN KAYIT İŞLEMLERİNE YÖNELİK BİLGİLENDİRME VE
        AÇIK RIZA METNİ
      </header>
      <p className="documentDate">Tarih:</p>
      <div className="documentColumn">
        <h2>BİLGİLENDİRME METNİ</h2>
        <div>
          Sevgili veli, vasi ve temsilcilerimiz; öğrencilerimize ve sizlere
          yönelik kişisel verilerin işlenmesi süreçleri ile ilgili sizlere
          sunduğumuz Aydınlatma Metninde belirttiğimiz üzere, bazı kişisel
          verileriniz ancak sizlerin vereceği açık rıza kapsamında işlenebilecek
          ve aktarılabilecektir. Öğrenciler için gereken açık rıza da veli, vasi
          veya temsilci tarafından verilebilir. İşbu metin açık rızanız
          dahilindeki kişisel veri işleme süreçlerimiz ile ilgili bilgilendirme
          amacıyla hazırlanmıştır.
        </div>
        <div>
          <strong>
            Öncelikle belirtmek isteriz ki aşağıda yer alan süreçlerde
            belirtilen kişisel verilerin işlenmesi için açık rıza verme
            zorunluluğunuz bulunmamaktadır.
          </strong>{" "}
          Lütfen, açık rızanızın bulunduğu hususlarda ilgili sürecin yanında yer
          alan kutucuğa tıklayınız. Açık rızanızın bulunmadığı süreçleri ise boş
          bırakınız. Açık rızanızın bulunmadığı süreçlere ilişkin kişisel
          verileriniz işlenmeyecektir.
        </div>
        <div>
          Açık rıza vermeniz halinde;
          <ul>
            <li>
              Ön kayıt işlemleri ve öğrenim süreci ile ilgili olmayan; ancak
              öğrenciyi tanımaya yönelik olan bilgilerinizi kayıt altına
              alabilir, öğrencimiz ile iletişimimizde bu bilgilerinizden
              faydalanabiliriz.
            </li>
            <li>
              Tarafınıza reklam, kampanya, promosyon faaliyetlerinin yürütülmesi
              amacıyla kimlik ve iletişim bilgilerinizi kullanabilir, Kampüs
              Kreş faaliyetleri ile ilgili olarak tarafınıza e-posta, SMS
              gönderebilir ve çağrı yapabiliriz.
            </li>
          </ul>
        </div>
      </div>
      <div className="documentColumn">
        <h2>AÇIK RIZA FORMU</h2>
        <div>
          6698 sayılı Kişisel Verilerin Korunması Kanunu ve ek mevzuatı
          kapsamında İstanbul Dudullu OSB Kampüs Kreş ve Gündüz Bakımevi’nin
          öğrenci-veli-vasi-temsilci ön kayıt işlemlerine yönelik olarak
          hazırlamış olduğu aydınlatma metnini okudum ve anladım. Kişisel
          verilerin işlenmesi ve aktarılmasına yönelik olarak gerekli
          bilgilendirme tarafıma yapılmıştır. Bu kapsamda; kişisel verilerin
          işlenmesine ilişkin olarak yukarıda belirtilen amaçlarla;
          <ul>
            <li>
              Kampüs Kreş tarafından reklam, kampanya, promosyon faaliyetlerinin
              yürütülmesi amacıyla kimlik ve iletişim bilgilerimin
              kullanılmasına, tarafıma e-posta (____) gönderilmesine, tarafıma
              SMS gönderilmesine (___), tarafımla çağrı merkezi aracılığıyla
              iletişime geçilmesine (___) açık rızam _________________.
            </li>
          </ul>
        </div>
      </div>
    </article>
  );
}
