import React from "react";
import { Helmet } from "react-helmet";

const page = [
  { pathName: "saglik", pageName: "Sağlık" },
  function () {
    return (
      <div className="subPages_hizmetlerimiz-defaultCard IMG_4466">
        <Helmet>
        <title>Kampüs Kreş - Sağlık</title>
        <meta name="title" content="Kampüs Kreş - Sağlık" />
        <meta property="og:title" content="Kampüs Kreş - Sağlık" />
        <meta name="twitter:title" content="Kampüs Kreş - Sağlık" />
      </Helmet>
        <div className="subPages_hizmetlerimiz-defaultCard_firstColumn">
          <p>
            Kreşimizde donanımlı bir revirimiz mevcuttur. Öğrencilerimizin
            sağlıkla ilgili sorunlarında ilk müdahalelerini yapabilecek tam gün
            hizmet veren bir hemşiremiz ve gerektiğinde destek veren danışman
            doktorlarımız bulunmaktadır.
          </p>
          <p>
            Ayrıca çocuklarımızın rutin boy-kilo ölçümleri hemşiremiz tarafından
            yapılmaktadır. Danışman doktorumuz tarafından ise öğretmenlerimize
            ve personelimize ilkyardım, hijyen, sağlık gibi konularda hizmet içi
            eğitimler verilmektedir.
          </p>
          <div
            className="img"
            style={{
              background: `url(${require("../../../../assets/IMG_4466.jpg")})`,
            }}
          />
        </div>
        <div className="subPages_hizmetlerimiz-defaultCard_secondColumn">
          <p>
            Alerji ve özel takip gerektiren rahatsızlıkları olan çocuklarımızın
            takibinin yapılması, hasta olduğu tespit edilen çocukların velileri
            ile görüşülerek gerekli tedbirlerin alınması ve yönlendirmelerin
            yapılması ile ilgili konularda görev yapmaktadır. Hemşiremizden…
            Okul öncesi dönemde çocuklar, vücut ve beyin gelişimi açısından
            hızlı büyüme gösterirler. Çocuklarımızı mikroplardan ne kadar
            korumaya çalışsak da, bağışıklık sistemleri henüz gelişmediğinden
            bünyeleri mikroplara açıktır, bu yüzden de bazen hastalanabilirler.
            Bağışıklık sistemleri güçlü olsun diye evden dışarı çıkmalarına izin
            vermemek, çocukların sağlıklı büyümelerini sağlamaz. Aksine
            bağışıklık sistemleri mikroba alışık olmadığından, kalabalık bir
            ortama girdiklerinde çabuk hastalanmalarına neden olur. Yapılan
            araştırmalara göre, okul öncesi dönemde yuvaya giden bazı çocuklar o
            dönemde sık hastalansalar da, sonrasında bağışıklık sistemleri
            geçirdikleri enfeksiyonlarla güçlenip mikroplara karşı dayanıklılık
            kazandığından daha az hastalanmaktadırlar. İlkokul dönemine
            geldiklerinde ise, yuvaya gitmeyen çocuklara göre okul hayatında
            hastalık nedeniyle daha az devamsızlık yaparlar. Sonuç olarak,
            çocuklarınızı steril ortamda büyütmeyin. Erken yaşta topluma
            girmelerine izin verin. Unutmayın ki erken yaşlarda geçirecekleri
            “tabi enfeksiyon hastalıklar” sayesinde ileride daha ciddi ve kronik
            hastalıklara yakalanma riski azalacaktır.
          </p>
        </div>
      </div>
    );
  },
];

export default page;
