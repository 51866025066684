import React from "react";
import { Helmet } from "react-helmet";
import { importAll } from "../../../../tools/importAll";

const page = [
  { pathName: "veli-avantajlari", pageName: "Velilerimize Avantajlar" },
  function () {
    const images = importAll(
      require.context(
        "../../../../assets/avantajlar",
        false,
        /(.png|.jpg|.webp)$/
      )
    );
    return (
      <div className="subPages_hizmetlerimiz-avantajlar_wrap">
        <Helmet>
        <title>Kampüs Kreş - Velilerimize Avantajlar</title>
        <meta name="title" content="Kampüs Kreş - Velilerimize Avantajlar" />
        <meta property="og:title" content="Kampüs Kreş - Velilerimize Avantajlar" />
        <meta name="twitter:title" content="Kampüs Kreş - Velilerimize Avantajlar" />
      </Helmet>
        {Object.keys(images).map((key, i) => (
          <img key={i} alt={`kampüs kreş avantajı - ${i}`} src={images[key]} />
        ))}
      </div>
    );
  },
];

export default page;
