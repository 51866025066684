import React, { useEffect, useRef, useState } from "react";
import pcVideo from "../../assets/birthday/web.mp4";
import mobVideo from "../../assets/birthday/mobil.mp4";
import { ScreenSize } from "../../tools/dimensions";
import Logo from "../../assets/compressed/logo.webp";
import ReplaySVG from "../../assets/icons/replay.svg";
import { NotFound } from "../../components/NotFound";

import "./styles.birthday.css";
import { SpinnerIcon } from "../../assets/icons";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function BirthDayPage({ params, hideNavigation }) {
  const name = decodeURIComponent(new URLSearchParams(params).get("n"));
  const videoRef = useRef(null);
  const prevTextRef = useRef(null);
  const currentTextRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [videoTime, setVideoTime] = useState(0);

  const { winWidth } = ScreenSize();
  const mobileWidth = 675; // pixels
  const [display, setDisplay] = useState(
    winWidth > mobileWidth ? "PC" : "MOBILE"
  );

  const videoStyle = videoVisible ? {} : { display: "none" };
  const cardStyle = !videoVisible ? {} : { display: "none" };
  const wrapperStyle = videoVisible ? { background: "#a1f0f0" } : {};
  const replayStyle = videoTime > 7 ? {} : { opacity: 0 };

  const texts = [
    `Sevgili ${name} 💖`,
    "Kampüs Kreş ailesi olarak;",
    "yeni yaşınız size",
    "başarı, şans,",
    "sağlık ve mutluluk",
    "getirmesini dileriz...",
    "Ailemizin bir parçası olduğunuz için",
    "teşekkür ederiz...",
    "Nice mutlu yıllara!",
  ];

  const [currentText, setCurrentText] = useState("");
  const [prevText, setPrevText] = useState("");

  function handleClick() {
    if (videoLoaded) {
      videoRef.current.play();
      // setVideoVisible(true);
    }
  }

  function animateTextChange() {
    // pre text
    let iP = 0;
    const intervalP = setInterval(() => {
      const opacity = 1 - iP * 0.1;

      prevTextRef.current.style.opacity = opacity;

      if (iP === 10) {
        clearInterval(intervalP);
      } else iP++;
    }, 50);

    // curr text
    let iC = 0;
    const intervalC = setInterval(async () => {
      const opacity = iC * 0.1;

      currentTextRef.current.style.opacity = opacity;

      if (iC === 0) await delay(500);

      if (iC === 10) {
        clearInterval(intervalC);
      } else iC++;
    }, 50);
  }

  useEffect(() => {
    if (videoLoaded) {
      const time = videoRef.current.currentTime;
      const duration = videoRef.current.duration;
      const frameTime = Math.round(duration) / texts.length;

      const nextText = texts[Math.round(time / frameTime)];

      if (currentText !== nextText) {
        animateTextChange();
        async function setTexts() {
          setPrevText(currentText);
          await delay(200);
          setCurrentText(nextText);
        }
        setTexts();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoLoaded, videoTime]);

  useEffect(() => {
    if (winWidth > mobileWidth && display !== "PC") {
      setDisplay("PC");
      videoRef.current.load();
      if (videoVisible) {
        setVideoTime(0);
        handleClick();
      }
    } else if (!(winWidth > mobileWidth) && display !== "MOBILE") {
      setDisplay("MOBILE");
      videoRef.current.load();
      if (videoVisible) {
        setVideoTime(0);
        handleClick();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winWidth]);

  useEffect(() => {
    hideNavigation(true); // Hide footer and nav bar
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (name === "null") return <NotFound title="Aradığınız Sayfa Bulunamadı" />;

  return (
    <div style={wrapperStyle} className="birthdayWrapper">
      {!videoLoaded ? (
        <div
          style={{
            position: "absolute",
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
          }}
          className="loading"
        >
          <SpinnerIcon />
          <p>Sayfa Yükleniyor</p>
        </div>
      ) : (
        <div style={cardStyle} className="birthdayCard">
          <img src={Logo} alt="Kampuskres logo" />
          <h1>Size bir mesajımız var</h1>
          <button
            type="button"
            className="birthdayButton"
            onClick={handleClick}
          >
            Mesajı Görüntüle
          </button>
        </div>
      )}

      <div style={videoStyle} className="videoWrapper">
        <div className="textFlow">
          <p ref={prevTextRef} id="prev">
            {prevText}
          </p>
          <p ref={currentTextRef} id="current">
            {currentText}
          </p>
        </div>
        <button
          type="reset"
          onClick={() => {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
            setVideoTime(0);
            videoRef.current.play();
          }}
          style={replayStyle}
          className="replayButton"
        >
          <img src={ReplaySVG} alt="replay button" />
          <p>Tekrar Oynat</p>
        </button>
        <video
          ref={videoRef}
          preload="auto"
          controls={false}
          onCanPlayThrough={() => setVideoLoaded(true)}
          onTimeUpdate={() => setVideoTime((t) => t + 1)}
          onPlaying={() => setVideoVisible(true)}
          autoPlay
          playsInline
        >
          {display === "MOBILE" ? (
            <source id="mobile" src={mobVideo} type="video/mp4"></source>
          ) : (
            <source id="pc" src={pcVideo} type="video/mp4"></source>
          )}
        </video>
      </div>
    </div>
  );
}
