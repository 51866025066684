import React from "react";
import ReactMarkdown from "react-markdown";
import { CloseIcon } from "../../../../../assets/icons";

export function AnnouncementCard({ children, title, thumbnail, isOn, id }) {
  const [modalOn, setModalState] = React.useState(isOn || false);

  return (
    <React.Fragment>
      {modalOn && (
        <React.Fragment>
          <div className="modal announcement">
            <div className="modalHeaderWrap announcement">
              <h1 className="modalTitle announcement">{title}</h1>
              <button
                className="modalCloseButton"
                onClick={() => setModalState((oldState) => !oldState)}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="announcementModalDetails">
              <img alt={`Kampüs Kreş Duyurusu: ${title}`} src={thumbnail} />
              <ReactMarkdown className="announcementModalDescription">
                {children}
              </ReactMarkdown>
            </div>
          </div>
          <div
            onClick={() => setModalState((oldState) => !oldState)}
            className="modalBg"
          />
        </React.Fragment>
      )}
      <div data-id={id} className="subPage_okulumuz-announcement_card">
        <div className="subPage_okulumuz-announcement_card_details">
          <img
            loading="lazy"
            src={thumbnail}
            alt={`Kampüs Kreş Duyurusu: ${title}`}
            className="subPage_okulumuz-announcement_card_image"
          />
          <h1 className="subPage_okulumuz-announcement_card_title">{title}</h1>
          <p className="subPage_okulumuz-announcement_card_description">
            {children}
          </p>
        </div>
        <button
          onClick={() => setModalState((oldState) => !oldState)}
          className="subPage_okulumuz-announcement_card_button"
        >
          Duyurunun Devamını Oku
        </button>
      </div>
    </React.Fragment>
  );
}
