import React from "react";
import { Field } from "formik";

import "./styles.form.checkInput.css";

export function CheckBoxInput(props) {
  const { children, surround, required, ...propItems } = props;

  if (surround) {
    return (
      <div className="formInputWrap">
        <div className="formCheckBoxInput">
          <Field
            {...propItems}
            type="checkbox"
            required={required ? true : false}
          />
          <label htmlFor={propItems.id}>{children}</label>
        </div>
      </div>
    );
  } else {
    return (
      <div className="formCheckBoxInput">
        <Field
          {...propItems}
          type="checkbox"
          required={required ? true : false}
        />
        <label htmlFor={propItems.id}>{children}</label>
      </div>
    );
  }
}
