import React from "react";

import "./style.footer.css";

export function Footer() {
  return (
    <footer>
      <div>
        <p>Kampüs Kreş, tüm hakları saklıdır. © 2022</p>
        <div className="line" />
        <ul>
          <li>
            <a href="/okulumuz">Okulumuz</a>
          </li>
          <li>
            <a href="/hizmetlerimiz">Hizmetlerimiz</a>
          </li>
          <li>
            <a href="/galeri">Galeri</a>
          </li>
          <li>
            <a href="/makaleler">Makaleler</a>
          </li>
          <li>
            <a href="/basinda-biz">Basında Biz</a>
          </li>
          <li>
            <a href="/#contact">İletişim</a>
          </li>
          <li>
            <a href="/kvkk">KVKK</a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
