import { gql } from "@apollo/client";

export const getFormDetails = gql`
  query getFormDetails {
    getFields {
      textFields {
        _id
        fieldName
        required
      }
      booleanFields {
        _id
        fieldName
        required
      }
      dateFields {
        _id
        fieldName
        required
      }
    }
    getQuotas {
      _id
      openQuota
      ageRange
    }
  }
`;

export const getRegistryForms = gql`
  query GetRegistryForms($session: String!) {
    getRegistryForms(session: $session) {
      _id
      name
      gender
      birth
      classType
      applicator {
        name
        degreeOfCloseness
        inOSB
        phoneNumber
        email
      }
      textFields {
        _id
        fieldName
        input
      }
      booleanFields {
        _id
        fieldName
        input
      }
      dateFields {
        _id
        fieldName
        input
      }
    }
  }
`;

export const getRegistryForm = gql`
  query ($_id: Int!, $session: String!) {
    getRegistryForm(_id: $_id, session: $session) {
      _id
      name
      gender
      birth
      classType
      applicator {
        name
        degreeOfCloseness
        inOSB
        phoneNumber
        email
      }
      textFields {
        _id
        fieldName
        input
      }
      booleanFields {
        _id
        fieldName
        input
      }
      dateFields {
        _id
        fieldName
        input
      }
    }
  }
`;

export const checkAdmin = gql`
  query ($username: String!, $password: String!) {
    checkAdmin(username: $username, password: $password) {
      adminType
      isAdmin
      session
    }
  }
`;

export const checkSession = gql`
  query ($session: String!) {
    checkSession(session: $session) {
      adminType
      isAdmin
    }
  }
`;

export const getImages = gql`
  query getImages($type: Int) {
    getImages(type: $type) {
      _id
      type
      displayName
      location
      thumbnail_location
    }
  }
`;

export const getAnnouncements = gql`
  query getAnnouncements($skip: Int, $limit: Int) {
    getAnnouncements(skip: $skip, limit: $limit) {
      announcements {
        _id
        title
        description
        thumbnail
      }
      max
    }
  }
`;

export const getAnnouncementsWithoutThumbnail = gql`
  query getAnnouncements($skip: Int, $limit: Int) {
    getAnnouncements(skip: $skip, limit: $limit) {
      announcements {
        _id
        title
        description
      }
    }
  }
`;
