import React from "react";

import { Formik, Field } from "formik";
import * as Yup from "yup";

import { checkAdmin } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";

import "./styles.login.css";
import { Helmet } from "react-helmet";

export function LoginPage({ params }) {
  const kampusAdminLogo = require("../../../assets/kampusadmin.png");

  const [input, setInput] = React.useState(null);

  const { refetch } = useQuery(checkAdmin, {
    variables: {
      username: "",
      password: "",
    },
  });

  var session;

  const LogInSchema = Yup.object().shape({
    username: Yup.string().required("Zorunlu bolge"),
    password: Yup.string()
      .required("Zorunlu bolge")
      .test("is-admin", "Kullanici Adi veya Sifre yanlis", async (value) => {
        let check = await refetch({ username: input, password: value });
        if (check.data.checkAdmin.isAdmin)
          session = check.data.checkAdmin.session;
        return check.data.checkAdmin.isAdmin;
      }),
  });

  return (
    <Formik
      initialValues={{ username: "", password: "" }}
      validationSchema={LogInSchema}
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      onSubmit={() => {
        window.localStorage.setItem("session", session);
        window.location.href = `/admin${
          params?.length > 1
            ? `${decodeURIComponent(new URLSearchParams(params).get("next"))}`
            : ""
        }`;
      }}
    >
      {(props) => (
        <form className="loginForm" onSubmit={props.handleSubmit}>
          <Helmet>
            <title>Kampüs Kreş - Admin Giriş</title>
            <meta name="title" content="Kampüs Kreş - Admin Giriş" />
            <meta property="og:title" content="Kampüs Kreş - Admin Giriş" />
            <meta name="twitter:title" content="Kampüs Kreş - Admin Giriş" />
          </Helmet>
          <img
            className="adminLogo"
            alt="Kampus Kres Admin Logosu"
            src={kampusAdminLogo}
          />
          <label htmlFor="kampusKresUsername">
            Kullanıcı Adı:
            <Field
              onChange={(e) => {
                props.handleChange(e);
                setInput(e.target.value);
              }}
              id="kampusKresUsername"
              name="username"
              type="text"
            />
            {props.errors.username && props.touched.username ? (
              <span>{props.errors.username}</span>
            ) : null}
          </label>
          <label htmlFor="kampusKresUsername">
            Şifre:
            <Field
              onChange={props.handleChange}
              id="kampusKresPassword"
              name="password"
              type="password"
            />
            {props.errors.password && props.touched.password ? (
              <span>{props.errors.password}</span>
            ) : null}
          </label>
          <button type="submit">Giriş Yap</button>
        </form>
      )}
    </Formik>
  );
}
