import React from "react";
import { Helmet } from "react-helmet";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";
import { ChevronLeftIcon, ChevronRightIcon } from "../../../../assets/icons";

const page = [
  { pathName: "e-brosur", pageName: "E-Broşür" },
  class EBrosur extends React.Component {
    state = {
      numPages: null,
      pageNumber: 1,
    };

    onDocumentLoadSuccess = ({ numPages }) => {
      this.setState({ numPages });
    };

    render() {
      const { pageNumber, numPages } = this.state;

      return (
        <div className="subPages_okulumuz-ebrosur_whitespace">
          <Helmet>
        <title>Kampüs Kreş - E-Broşür</title>
        <meta name="title" content="Kampüs Kreş - E-Broşür" />
        <meta property="og:title" content="Kampüs Kreş - E-Broşür" />
        <meta name="twitter:title" content="Kampüs Kreş - E-Broşür" />

        <meta
      name="keywords"
      content="kreş tanıtım, tanıtım, kampüs kreş tanıtım"
    />
      </Helmet>
          {pageNumber - 1 !== 0 && (
            <button
              onClick={(e) => {
                e.preventDefault();
                this.setState((oldState) => {
                  return { pageNumber: oldState.pageNumber - 1 };
                });
              }}
              className="subPages_okulumuz-ebrosur_chevron left"
            >
              <ChevronLeftIcon />
            </button>
          )}
          <Document
            loading="E-Broşür Yükleniyor..."
            error="E-Borşür Yüklenirken Bir Hata Oluştu"
            className="subPages_okulumuz-ebrosur"
            onLoadSuccess={this.onDocumentLoadSuccess}
            file="/assets/e_brosur.pdf"
          >
            <Page
              className="subPages_okulumuz-ebrosur_page"
              pageNumber={pageNumber}
            />
          </Document>
          {pageNumber !== numPages && (
            <button
              onClick={(e) => {
                e.preventDefault();
                this.setState((oldState) => {
                  return { pageNumber: oldState.pageNumber + 1 };
                });
              }}
              className="subPages_okulumuz-ebrosur_chevron right"
            >
              <ChevronRightIcon />
            </button>
          )}
          <p className="subPages_okulumuz-ebrosur_pageDisplay">{pageNumber}</p>
        </div>
      );
    }
  },
];

export default page;
