import React from "react";
import { Helmet } from "react-helmet";

const page = [
  { pathName: "servis", pageName: "Servis" },
  function () {
    return (
      <div className="subPages_hizmetlerimiz-defaultCard TKM_7145">
        <Helmet>
        <title>Kampüs Kreş - Servis</title>
        <meta name="title" content="Kampüs Kreş - Servis" />
        <meta property="og:title" content="Kampüs Kreş - Servis" />
        <meta name="twitter:title" content="Kampüs Kreş - Servis" />
        
        <meta
      name="keywords"
      content="kreş servis hizmeti, servis hizmeti, kampüs kreş servis"
    />
      </Helmet>
        <div className="subPages_hizmetlerimiz-defaultCard_firstColumn">
          <p>
            Kreşimizde servis hizmeti için sektörün iyi bilinen firmalarından
            Ayar Tur ile çalışılmakta ve teknolojik olarak iyi donanıma sahip
            yeni araçlar kullanılmaktadır.
          </p>
          <div
            className="img"
            style={{
              background: `url(${require("../../../../assets/TKM_7145.jpg")})`,
            }}
          />
        </div>
        <div className="subPages_hizmetlerimiz-defaultCard_secondColumn">
          <p>
            Her koltukta emniyet kemeri ve bebeklerimiz için araba koltukları
            bulunmaktadır. Araçlarımız, Emniyet Müdürlüğü’nün belirlediği
            standartlara uygun olup, öğrencilerimiz yolculuk sırasında
            sigortalıdır. Öğrencilerimiz gözetmen eşliğinde evlerinden alınıp
            kreş öğretmenlerine teslim edilir, yine aynı şekilde gözetmen
            tarafından kreşimizden teslim alınıp en güvenli, hızlı ve doğru
            şekilde evlerine bırakılırlar. Öğrenciler okula servis ile
            bırakıldıktan ve servis tamamen boşaltıldıktan sonra güvenlik
            görevlileri servis araçlarının içine girerek son kontrollerini yapar
            ve servis araçlarını gönderirler.
          </p>
        </div>
      </div>
    );
  },
];

export default page;
