import { useMutation, useQuery } from "@apollo/client";
import axios from "axios";
import React from "react";
import { Helmet } from "react-helmet";
import { SpinnerIcon } from "../../../assets/icons";
import {
  createAnnouncement,
  deleteAnnouncement,
} from "../../../graphql/mutations";
import { getAnnouncementsWithoutThumbnail } from "../../../graphql/queries";
import { toUrlSafe } from "../../../tools/urlSafeFormat";

export function AdminAnnouncements() {
  const {
    data: announcements,
    loading: loadingAnnouncements,
    refetch,
  } = useQuery(getAnnouncementsWithoutThumbnail);

  const [createAnnouncementParent, { data, loading, error }] =
    useMutation(createAnnouncement);

  const [deleteAnnouncementParent, { error: deleteAnnouncementError }] =
    useMutation(deleteAnnouncement);

  const [announcement, setAnnouncement] = React.useState({});
  const [statusMessage, setStatusMessage] = React.useState("");
  const [currentAnnouncement, setCurrentAnnouncement] = React.useState(null);

  React.useEffect(() => {
    if (!loading && data) {
      setStatusMessage(
        `#${data.createAnnouncement._id} ID'li Duyuru Oluşturuldu`
      );
    }
  }, [loading, data]);

  React.useEffect(() => {
    if (statusMessage) {
      setAnnouncement({ title: "", description: "" });
      setTimeout(() => {
        setStatusMessage("");
      }, 2000);
    }
  }, [statusMessage]);

  async function createImage(imageFile) {
    const fd = new FormData();
    fd.append("options", JSON.stringify({ saveToAnnouncements: true }));
    fd.append("file", imageFile, imageFile.name);
    fd.append("session", window.localStorage.getItem("session"));

    let res = await axios
      .post(`${process.env.REACT_APP_API_HOST}/images/image/upload`, fd, {
        onUploadProgress: (progressEvent) => {
          setStatusMessage(
            "Duyuru fotoğrafı yükleniyor... " +
              Math.round((progressEvent.loaded / progressEvent.total) * 100) +
              "%"
          );
        },
      })
      .catch((err) => {
        console.log(err);
        return null;
      });

    if (res.status === 200) {
      setStatusMessage("Duyuru fotoğrafı kaydedildi.");
      return res.data.location;
    }
  }

  async function saveAnnouncementToDB() {
    try {
      let imageLocation = await createImage(announcement.file);

      if (!imageLocation) throw new Error("Unable to upload picture.");

      await createAnnouncementParent({
        variables: {
          title: announcement.title,
          description: announcement.description,
          thumbnail: imageLocation,
          session: window.localStorage.getItem("session"),
        },
      });
      if (error) throw new Error(error);
      refetch();
    } catch (err) {
      setStatusMessage("Duyuru Kaydedilirken Bir Hata Oluştu.");
      throw new Error(err);
    }
  }

  async function deleteAnnouncementFromDB(_id) {
    try {
      await deleteAnnouncementParent({
        variables: {
          _id,
          session: window.localStorage.getItem("session"),
        },
      });
      if (deleteAnnouncementError) {
        setCurrentAnnouncement({});
        throw new Error(deleteAnnouncementError);
      }
      refetch();
    } catch (err) {
      setCurrentAnnouncement({});
      throw new Error(err);
    }
  }

  function replaceURLWithMarkdown(text) {
    const regex = /(https?:\/\/[^\s]+)/gi;
    return text.replace(regex, (url) => `[${url}](${url})`);
  }

  function addNewLineTag(text) {
    return text.replace(/\n/g, "\n\n");
  }

  return (
    <div>
      <Helmet>
        <title>Kampüs Kreş - Admin</title>
        <meta name="title" content="Kampüs Kreş - Admin" />
        <meta property="og:title" content="Kampüs Kreş - Admin" />
        <meta name="twitter:title" content="Kampüs Kreş - Admin" />
      </Helmet>
      <div className="admin_form_wrap">
        <div className="admin_image_dragDrop">
          <span>
            {announcement?.thumbnail ? "Fotoğraf Değiştir" : "Fotoğraf Seç"}
          </span>
          <p>
            {announcement?.file
              ? announcement.file.name
              : "veya Buraya Sürükle"}
          </p>
          <input
            onChange={(e) => {
              setAnnouncement((oldObj) => ({
                ...oldObj,
                file: e.target.files[0],
              }));
              if (e.target.files[0] === undefined)
                setAnnouncement((oldObj) => ({
                  ...oldObj,
                  file: undefined,
                }));
            }}
            accept="image/png, image/jpeg, image/webp"
            type="file"
          />
        </div>
        <div className="admin_inputWrap">
          <label htmlFor="announcementTitle">Duyuru Başlığı</label>
          <input
            id="announcementTitle"
            onChange={(e) =>
              setAnnouncement((oldObj) => ({
                ...oldObj,
                title: e.target.value,
              }))
            }
            placeholder="Kampüs Kreş Kontenjan Durumu..."
            type="text"
            value={announcement?.title}
          />
        </div>
        <div className="admin_inputWrap">
          <label htmlFor="announcementTitle">Duyuru</label>
          <textarea
            id="announcementDescription"
            onChange={(e) =>
              setAnnouncement((oldObj) => ({
                ...oldObj,
                description: replaceURLWithMarkdown(
                  addNewLineTag(e.target.value)
                ),
              }))
            }
            placeholder={`2021-2022 Eğitim öğretim yılı kontenjanlarımız dolmuştur, yoğun ilginiz için teşekkür ederiz.
            
Kampüs Kreş Yönetimi`}
            type="text"
            value={announcements?.description}
          />
        </div>
        <span>{statusMessage}</span>
        <button
          className="admin_save_button"
          style={
            announcement &&
            announcement.title &&
            announcement.description &&
            announcement.file
              ? { cursor: "pointer" }
              : { opacity: ".5", cursor: "not-allowed" }
          }
          disabled={
            !announcement ||
            !announcement.title ||
            !announcement.description ||
            !announcement.file
          }
          onClick={() => {
            if (
              announcement &&
              announcement.title &&
              announcement.description &&
              announcement.file
            ) {
              saveAnnouncementToDB();
            }
          }}
        >
          Duyuruyu Paylaş
        </button>
      </div>
      <table className="data_table">
        <tbody>
          <tr>
            <th>Duyuru Başlığı</th>
            <th>ID</th>
            <th>&nbsp;</th>
          </tr>
        </tbody>
        {loadingAnnouncements && (
          <tbody>
            <tr>
              <td
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="loading">
                  <SpinnerIcon />
                  <p>Duyurular Yükleniyor</p>
                </div>
              </td>
            </tr>
          </tbody>
        )}
        {!loadingAnnouncements &&
          announcements.getAnnouncements.announcements.map(
            (announcementData, i) => (
              <tbody>
                <tr key={i}>
                  <td>
                    <p>{toUrlSafe(announcementData.title)}</p>
                  </td>
                  <td>
                    <p>#{announcementData._id}</p>
                  </td>
                  <td>
                    <button
                      style={
                        currentAnnouncement?._id === announcementData._id
                          ? {
                              background: "#f692001a",
                              borderColor: "var(--brand)",
                              color: "var(--brand)",
                            }
                          : {}
                      }
                      onClick={() => {
                        setCurrentAnnouncement({
                          _id: announcementData._id,
                          message: "Duyuru Siliniyor...",
                        });
                        deleteAnnouncementFromDB(announcementData._id);
                      }}
                    >
                      {currentAnnouncement?._id === announcementData._id
                        ? currentAnnouncement.message
                        : "Duyuruyu Sil"}
                    </button>
                  </td>
                </tr>
              </tbody>
            )
          )}
      </table>
    </div>
  );
}
