import React, { useState } from "react";
import { Field } from "formik";

import "./styles.form.dateInput.css";

import MarkResolver from "../MarkResolver.json";

export function DateInput(props) {
  const { children, onChange, required, ...propItems } = props;

  const [active, setActive] = useState(false);

  return (
    <div className="formInputWrap">
      <label htmlFor={propItems.id}>
        {MarkResolver.includes(children.charAt(children.length - 1))
          ? children
          : children + ":"}
        {required && <span>&nbsp;*</span>}
      </label>
      <Field
        onChange={(e) => {
          onChange(e);
          if (e.target.value.length > 0) {
            setActive(true);
          } else {
            setActive(false);
          }
        }}
        className={`formDateInput active_${active}`}
        {...propItems}
        lang="tr"
        type="date"
        required={required ? true : false}
      />
    </div>
  );
}
