import React from "react";

import { GoogleMap, useLoadScript } from "@react-google-maps/api";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100%",
  height: "100%",
};
const center = {
  lat: 41.00355,
  lng: 29.177124,
};
const options = {
  styles: [
    {
      featureType: "all",
      elementType: "all",
      stylers: [
        {
          saturation: "32",
        },
        {
          lightness: "-3",
        },
        {
          visibility: "on",
        },
        {
          weight: "1.18",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "landscape.man_made",
      elementType: "all",
      stylers: [
        {
          saturation: "-70",
        },
        {
          lightness: "14",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          saturation: "100",
        },
        {
          lightness: "-14",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
        {
          lightness: "12",
        },
      ],
    },
  ],
  disableDefaultUI: true,
  zoomControl: true,
  streetViewControl: true,
};

export function Map() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    libraries,
  });

  if (loadError) return "Harita Yüklenirken Bir Hata Oluştu";
  if (!isLoaded) return "Harita Yükleniyor...";

  return (
    <div className="googleMap">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={19}
        center={center}
        options={options}
      ></GoogleMap>
    </div>
  );
}
