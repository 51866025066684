import { useQuery } from "@apollo/client";
import React from "react";
import { Helmet } from "react-helmet";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  SpinnerIcon,
} from "../../../../assets/icons";
import { NotFound } from "../../../../components/NotFound";
import { getAnnouncements } from "../../../../graphql/queries";
import { AnnouncementCard } from "./components/announcementCard";

const page = [
  { pathName: "duyurular", pageName: "Duyurular" },
  function () {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");

    const [page, setPage] = React.useState(1);
    const [noPrev, setNoPrev] = React.useState(false);
    const [noNext, setNoNext] = React.useState(false);

    const { data, loading, refetch } = useQuery(getAnnouncements, {
      variables: { skip: 0, limit: 8 },
      notifyOnNetworkStatusChange: true,
    });

    React.useEffect(() => {
      refetch({ skip: (page - 1) * 8, limit: 8 });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    React.useEffect(() => {
      if (!loading) {
        if (page === 1) {
          setNoPrev(true);
        } else if (noPrev === true) {
          setNoPrev(false);
        }

        if (page * 8 >= data.getAnnouncements.max) {
          setNoNext(true);
        } else if (noNext === true) {
          setNoNext(false);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, loading]);

    function changePage(changeTo) {
      if (!changeTo) return;

      if (changeTo === "next") {
        setPage((oldPage) => oldPage + 1);
      }

      if (changeTo === "prev") {
        setPage((oldPage) => oldPage - 1);
      }
    }

    if (loading)
      return (
        <div
          style={{
            position: "absolute",
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
          }}
          className="loading"
        >
          <SpinnerIcon />
          <p>Sayfa Yükleniyor</p>
        </div>
      );

    if (data.getAnnouncements.announcements.length === 0) {
      return <NotFound title="Gösterilecek Duyuru Bulunmamakta" />;
    }

    return (
      !loading && (
        <React.Fragment>
          <Helmet>
            <title>Kampüs Kreş - Duyurular</title>
            <meta name="title" content="Kampüs Kreş - Duyurular" />
            <meta property="og:title" content="Kampüs Kreş - Duyurular" />
            <meta name="twitter:title" content="Kampüs Kreş - Duyurular" />

            <meta
              name="keywords"
              content="kreş haberler, duyurular, kampüs kreş haberler, kampüs kreş duyurular"
            />
          </Helmet>
          <div className="subPage_okulumuz-announcements_wrap">
            {data?.getAnnouncements.announcements.map((announcement, i) => (
              <AnnouncementCard
                key={i}
                id={announcement._id}
                title={announcement.title}
                thumbnail={announcement.thumbnail}
                isOn={id === announcement._id}
              >
                {announcement.description}
              </AnnouncementCard>
            ))}
          </div>
          <div className="pageControls">
            <button
              style={{ opacity: noPrev ? 0 : 1 }}
              onClick={() => !noPrev && changePage("prev")}
            >
              <ChevronLeftIcon />
            </button>
            <span>{page}</span>
            <button
              style={{ opacity: noNext ? 0 : 1 }}
              onClick={() => !noNext && changePage("next")}
            >
              <ChevronRightIcon />
            </button>
          </div>
        </React.Fragment>
      )
    );
  },
];

export default page;
