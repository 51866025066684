import React from "react";
import { Helmet } from "react-helmet";

const page = [
  { pathName: "hakkimizda", pageName: "Hakkımızda", defaultView: true },
  function () {
    return (
      <div className="subPage_okulumuz-aboutUs">
        <Helmet>
        <title>Kampüs Kreş - Hakkımızda</title>
        <meta name="title" content="Kampüs Kreş - Hakkımızda" />
        <meta property="og:title" content="Kampüs Kreş - Hakkımızda" />
        <meta name="twitter:title" content="Kampüs Kreş - Hakkımızda" />
      </Helmet>
        <div className="subPage_okulumuz-aboutUs_imageAndQuote">
          <img
            alt="Füsun Kunt"
            className="subPage_okulumuz-aboutUs_image"
            src={require("../../../../assets/compressed/fusunkunt.webp")}
          />
          <p className="subPage_okulumuz-aboutUs_quote">
            “Kapımızı her gün ilk günkü heyecan, umut ve sevgi ile açmaktayız ve
            dünyanın en değerli varlıklarını bu inançla karşılamaktayız.”
          </p>
        </div>
        <p>
          Değerli Velilerimiz,
          <br />
          <br />
          Çalışan annelerin en büyük sorunu olan çocuk bakım ihtiyacı nedeniyle,
          0-6 yaş arası çocuklara yönelik olarak tasarlanmış, özel mimari ve
          akıllı bina teknolojisi ile inşa edilmiş kreşimiz, 2012-2013
          Eğitim-Öğretim döneminde hizmete girmiştir.
          <br />
          <br />
          Kreşimizde, çocuklarımızın en iyi eğitimi alması amacının yanında,
          güvenlikleri ve konforları açısından da üst düzey detaylar göz önünde
          tutulmuştur. Kampüs Kreş, çevre yerleşim yerleri için kolaylıkla
          ulaşılabilecek konumu, fiziksel altyapısı, izlediği eğitim metodları
          ve profesyonel kadrosu ile Türkiye’nin en iyi kreşi olma vizyonuna
          sahiptir.
          <br />
          <br />
          Okulumuzda “Her çocuk özeldir” ilkesi doğrultusunda öğrenci merkezli
          bir anlayış benimsenir. Kendine güvenen, özgür, bağımsız kararlar alıp
          uygulayabilen, insani değerlere sahip, yaratıcı, girişken, etkili
          iletişim kurabilen, sorun çözebilen bireyler yetiştirmek temel
          hedefimizdir.
          <br />
          <br />
          “Türkiye’nin en büyük kreşinde, her çocuk için özel bir yaşam” sloganı
          ile yola çıktığımız Kreşimizde, çocuklarımızın kendilerini özel ve
          ayrıcalıklı hissetmeleri için çalışırız. Velilerimiz, çocuklarını
          onlar kadar iyi tanıyan ve anlayan deneyimli eğitmenlere emanet
          ettiklerini bilir, eğitmenlerimiz her çocuğumuza geleceğin lideri,
          sanatçısı, düşünürü, iş adamı gözüyle yaklaşır ve onların
          farklılıklarını, yeteneklerini ortaya çıkarmaya çalışır. Tüm
          çocukların potansiyellerinin en üst düzeye çıkması öncelikli
          hedeflerimizdendir.
          <br />
          <br />
          Mutlu bir ekiple, mutlu nesiller yetiştirme gayretindeyiz. Gelecek
          güzel günlerde de siz velilerimiz ve parıldayan gençlerimizle buluşmak
          dileğiyle…
          <br />
          <br />
          Füsun Kunt
          <br />
          Kurucu Temsilcisi / Okul Müdürü
        </p>
      </div>
    );
  },
];

export default page;
