export function calcAgeYear(age) {
  const now = new Date().toLocaleDateString();
  const currentYear = parseInt(now.split(".")[2]);
  const currentYearMobile = parseInt(now.split("/")[2]);

  let ageYear =
    (currentYear.length !== 1 ? currentYear : currentYearMobile) -
    parseInt(age);

  return ageYear;
}
